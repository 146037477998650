import react from 'react';

class Card extends react.Component {

    render() {

        return (<>
            <div className="card border shadow-sm ">
                <div className="card-body" style={{ "overflow": "auto" }}>
                    <h6 className="card-title">{this.props.title ? this.props.title : ""}</h6>
                    {this.props.children}
                </div>
            </div>
        </>)
    }
}

export default Card