import './permission-error.scss';
import Grid from '@mui/material/Grid';
import { Link } from 'react-router-dom';

const PermissionError = () => {
    return (
        <div className='error-container'>
            <Grid container direction="column" rowSpacing={2}>
                <Grid item>
                    <div className='header-logo'>
                        <div className='header-text'>Ortho Clinical Diagnostics</div>
                    </div>
                </Grid>
                <Grid item>
                    <div className='error-conatiner'>
                        <p className='error-text'>Permission Error</p>
                        <p>We're sorry, you don't have permission to this area of our site. Please contact your administrator to be granted access.</p>
                    </div>
                </Grid>
                <Grid item>
                    <div className='footer-conainter'>

                        <div className='footer-links'>

                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="flex-start"
                            >

                                <Grid item>
                                    <a target="_blank" href="http://www.orthoclinicaldiagnostics.com/en-US/employee-notice/">Employee Notice</a>
                                </Grid>
                                <Grid item>
                                    <a target="_blank" href="http://www.orthoclinicaldiagnostics.com/en-US/legal-notice/">Legal Notice</a>
                                </Grid>
                                <Grid item>
                                    <a target="_blank" href="http://www.orthoclinicaldiagnostics.com/en-US/cookie-notice-disclosure/"> Cookie Notice & Disclosuree</a>
                                </Grid>

                                <Grid item>
                                    <a target="_blank" href="http://www.orthoclinicaldiagnostics.com/File%20Library/pdf/en/CA-OCD-NOTICE.pdf"> Declaration for California Compliance </a>

                                </Grid>

                            </Grid>
                        </div>

                        <p>© Ortho Clinical Diagnostics 2022. This site is published by Ortho Clinical Diagnostics, which is solely responsible for its contents. Product availability is subject to fulfillment of regulatory requirements in each market.</p>
                    </div>
                </Grid>
            </Grid>


        </div>
    )
}

export default PermissionError;