import react from 'react';
import Modal from '../modal/modal'

class DetailedStatusTable extends react.Component{


    constructor(props) {
        super(props)

        this.convertStringData = this.convertStringData.bind(this);
    }

    convertStringData(){
        // console.log("New Dtaa:::: "+this.props.myProp)
        const rows = this.props.myProp.split(",");
        return rows
    }
    

    render(){
        const rows = this.convertStringData();
        // console.log("New Dtaa:::: "+this.props.myProp)
        return(
            <>
                    <table className="table table-hover table-sm text-center ">
                            <tbody>
                                <tr>
                                {rows.map((item, index) => <tr key = {index}>{item}</tr>)}
                
                                </tr>
                         </tbody>
                    </table>      
                    </> 
        )
    }
}
export default DetailedStatusTable;