import get from '../../utils/http-utils'

export function getStatus() {
    return get("/Vision/zipfiles/lookupStatus")
        .then(resp => {
            const status = Object.values(resp.data).reduce((acc, val, ind) => {
                let tempObj = { key: val, value: val }
                acc.push(tempObj)
                return acc;
            }, [])
            return { data: status }

        }, err => {
            return { error: err.message };
        })
}