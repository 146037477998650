import * as React from 'react';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useEffect, useState } from 'react';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function CustomizedSnackbars(props) {
    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen(true);
    };

    useEffect(() => {
        props.snackbar.current = handleClick
      }, [])

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };


    return (
        <Stack spacing={2} sx={{ width: '100%' }}>
            <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
                {props.type === 'error' ?
                    <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                        {props.message}
                    </Alert>
                    :
                    <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                        {props.message}
                    </Alert>
                }
            </Snackbar>
        </Stack>
    );
}
