import react from 'react';

class Loader extends react.Component {

    render() {
        return (<div className="mx-auto mt-1 mb-2 spinner-border text-danger" role="status">
            <span className="visually-hidden">Loading...</span>
        </div>);
    }
}

export default Loader;