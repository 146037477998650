import { Pagination } from '@material-ui/lab';
import react from 'react';
import Loader from '../loader/loader';


class StrippedTable extends react.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedRow: -1
        }
    }


    render() {
        return (
            <>{
                this.props.data.length > 0 ?
                    (<>
                        <table className="table table-hover table-sm text-center ">
                            <thead>
                                <tr>
                                    {this.props.headers.map((val, index) => (<th scope="col" key={index}>{val}</th>))}
                                </tr>
                            </thead >
                            <tbody>
                                {this.props.data.map((val, index) => {
                                    return (<tr className={this.state.selectedRow === index ? "table-active" : ""} key={index} onClick={this.props.rowClick ? () => { this.props.rowClick(val); this.setState({ selectedRow: index }) } : () => { }} style={this.props.rowStyles ? this.props.rowStyles : {}}>
                                        {this.props.headers.map((value, ind) => (<td key={ind}>{val[value]}</td>))}
                                    </tr>)
                                })}
                            </tbody>
                        </table>
                        <div className="d-flex align-items-end flex-row-reverse">
                            {
                                this.props.paginationCallout ? (this.props.paginationLoaded ?
                                    <Pagination count={this.props.pageCount} defaultPage={this.props.currentPage} onChange={(event, page) => {
                                        this.props.paginationCallout({ page })
                                    }} shape="rounded" />
                                    : <div>{this.props.paginationMessage ?
                                        <span className="alert-danger">{this.props.paginationMessage}</span>
                                        : <Loader />}</div>) : ""
                            }
                        </div>
                    </>)

                    : (
                        <div>
                            <p className="text-center w-100 alert alert-warning">{this.props.message ? this.props.message : "No Data available to display."}</p>
                        </div>)
            }

                {this.props.children}
            </>
        )
    }
}

export default StrippedTable;