import react from 'react';
import './modal.scss';
import DetailedStatusTable from '../table/tableForDetailedStatus';

class ModalDetailedStatus extends react.Component {

    render() {
        return (
            <div className={this.props.showModal ? "modal-backdrop" : ""}>
                <div className={this.props.showModal ? "modal fade show d-block" : "modal fade"} id="exampleModalLong" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLongTitle">{this.props.modalTitle}</h5>
                                <button type="button" onClick={() => this.props.closeModal(-1)} className="btn-close" data-dismiss="modal" aria-label="Close">
                                </button>
                            </div>
                            <div className="modal-body">
                               <DetailedStatusTable myProp = {this.props.modalBody}/>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={() => this.props.closeModal(-1)} data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ModalDetailedStatus;