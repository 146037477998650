import axios from 'axios';
import { API_GATEWAY_VISION, API_GATEWAY_VITROS, DEPLOYMENT_REGION,API_GATEWAY_OPTIX } from './constants';
import { Auth } from 'aws-amplify'

export default async function get(url, headers = { "content-type": "application/json" }, params = {}) {
    let accessToken;
    let jwt;
    let cognitoSession;
    if (DEPLOYMENT_REGION === 'USA') {
        await Auth.currentSession().then(res => {
            accessToken = res.getAccessToken();

            jwt = res.getIdToken().getJwtToken();

            cognitoSession = res;
        });

        if (cognitoSession.isValid()) {
            headers.authorization = "Bearer " + jwt;
        } else {
            Auth.federatedSignIn();
            return "";
        }
    } else {
        console.log(localStorage.getItem('keycloakToken'))
        headers.authorization = `Bearer ${localStorage.getItem('keycloakToken')}`
    }
    if (url.includes("Vision")) {
        var visionURL = API_GATEWAY_VISION + url
        console.log("INSIDE ______-----------------_________ VISION:::: " + visionURL)

        return axios.get(API_GATEWAY_VISION + url, { headers, params });
    }else if(url.includes('Optix')){
        var optixURL = API_GATEWAY_OPTIX + url
        console.log("INSIDE ______-----------------_________ OPTIX:::: " + optixURL)
        return axios.get(optixURL, { headers, params });
    }else{
        var vitrosURL = API_GATEWAY_VITROS + url
        console.log("INSIDE ______-----------VITROS:::: " + vitrosURL)

        return axios.get(API_GATEWAY_VITROS + url, { headers, params });
    }
}

async function componseHeader( headers ) {
    let accessToken;
    let jwt;
    let cognitoSession;
    let apiheaders = headers;

    if (DEPLOYMENT_REGION === 'USA') {
        await Auth.currentSession().then(res => {
            accessToken = res.getAccessToken();

            jwt = res.getIdToken().getJwtToken();

            cognitoSession = res;
        });

        if (cognitoSession.isValid()) {
            apiheaders.authorization = "Bearer " + jwt;
        } else {
            Auth.federatedSignIn();
            return "";
        }
    } else {
        apiheaders.authorization = `Bearer ${localStorage.getItem('keycloakToken')}`
    }
    return apiheaders;
}

export async function post(url, headers = { "content-type": "application/json" }, requestBody = {}) {
    
    let componsedHeaders = await componseHeader(headers);
    let apiUrl = (!url.includes("Vision") ? API_GATEWAY_VITROS : API_GATEWAY_VISION) + url ;

    return axios.post(apiUrl, requestBody, { headers: componsedHeaders});
}

