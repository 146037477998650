import '../App.scss';
import React, {useEffect} from 'react';
import Navbar from '../components/navbar/navbar';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import VitrosStats from '../pages/Vitros/stats/stats';
import Contents from '../pages/Vitros/contents/contents';
import Errors from '../pages/Vitros/errors/errors';
import DatalakeContents from '../pages/Vitros/datalakeContents/datalake-contents';
import RedshiftContents from '../pages/Vitros/redshiftContents/redshift-contents';

import VisionStats from '../pages/Vision/VisionStats/VisionStats';
import VisionContents from '../pages/Vision/VisionContents/VisionContents';
import VisionRedshiftLoad from '../pages/Vision/VisionRedshiftLoad/VisionRedshiftLoad';
import VisionErrors from '../pages/Vision/VisionErrors/VisionErrors';

import OptixStats from '../pages/Optix/OptixStats/OptixStats'
import OptixContents from '../pages/Optix/OptixContents/OptixContents'
// import OptixRedshiftLoad from '../pages/Optix/OptixRedshiftLoad/VisionRedshiftLoad'
import OptixErrors from '../pages/Optix/OptixErrors/OptixErrors'

import { HOME_MACHINE_PAGE,SHOW_OPTIX_MENU,SHOW_VISION_MENU,SHOW_VITROS_MENU } from "./constants";
import DBFileManagement from '../pages/Vitros/dbFileManagement/db-file-management';
import FileCopyUtil from '../pages/FileCopyUtility/FileCopyUtil';

import Error_404 from '../pages/Error_404/Error_404';

const RouterInfo = (props) => {

  const returnComponent = (val, component) => {
    if(val === "TRUE"){
      return <>
      <Navbar signoutUser={props.signoutUser}/>
      <div className="container mt-5">
      {component}
      </div>
      </>
    }else{
      return <Redirect to="/"/>
    }
  }
  return <Router>
          <div>
            
              <Switch>

                <Route exact path='/' component={() => <Redirect to={HOME_MACHINE_PAGE} />} />

                <Route exact path="/Vitros" component={() => returnComponent(SHOW_VITROS_MENU,<VitrosStats />)}/>
                <Route exact path="/Vitros/errors" component={() => returnComponent(SHOW_VITROS_MENU, <Errors />)} />
                <Route exact path="/Vitros/contents" component={ () => returnComponent(SHOW_VITROS_MENU, <Contents />)}/>
                <Route exact path="/Vitros/datalakeContents" component={ () => returnComponent( SHOW_VITROS_MENU, <DatalakeContents />)}/>
                <Route exact path="/Vitros/redshiftContents" component={ () => returnComponent(SHOW_VITROS_MENU, <RedshiftContents />)}/>
                <Route exact path="/Vitros/dbFileManagement" component={ () => returnComponent(SHOW_VITROS_MENU, <DBFileManagement />)}/>
                <Route exact path="/Vitros/fileCopyUtil" component={ () => returnComponent(SHOW_VITROS_MENU, <FileCopyUtil />)}/>

                <Route exact path="/Vision" component={ () => returnComponent(SHOW_VISION_MENU, <VisionStats />)} />
                <Route exact path="/Vision/errors" component={ () => returnComponent(SHOW_VISION_MENU, <VisionErrors />)} />
                <Route exact path="/Vision/contents" component={ () => returnComponent(SHOW_VISION_MENU, <VisionContents />)} />
                <Route exact path="/Vision/redshiftLoad" component={ () => returnComponent(SHOW_VISION_MENU, <VisionRedshiftLoad />)} />

                <Route exact path="/Optix" component={() => returnComponent(SHOW_OPTIX_MENU,<OptixStats />)} />
                <Route exact path="/Optix/errors" component={ () => returnComponent(SHOW_OPTIX_MENU,<OptixErrors />)} />
                <Route exact path="/Optix/contents" component={ () => returnComponent(SHOW_OPTIX_MENU,<OptixContents />)} />

                <Route path="*" component={Error_404} />

              </Switch>

          </div>

        </Router>
};
export default RouterInfo;