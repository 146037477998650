import get from '../../utils/http-utils';

export function getJNumberInstrumentID (instrumentType) {
    return get("/dbfilemanagement/jnumberdata", { "content-type": "application/json" }, 
    {instrumentType});
}

export function getFileData (startDate, endDate, instrumentId) {
    return get("/dbfilemanagement/filedata", { "content-type": "application/json" }, 
    {startDate, endDate, instrumentId});
}

export function getInstrumentType () {
    return get("/dbfilemanagement/instrumenttype");
}