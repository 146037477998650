import get from '../../utils/http-utils';


export function getHealthStats(startDate = this.state.from, endDate = this.state.to, instrumentType = "") {
    //get health stats call out /getHealth
    const params = {
        startDate,
        endDate,
        instrumentType
    }
    return get("/Optix/zipfiles/health", { "content-type": "application/json" }, params).then(resp => {
        // console.log("response", resp);
        if (resp.data?.length > 0) {
            const healthStats = resp.data.reduce((acc, val, index, arr) => {
                if (val.message === "LOAD_C") acc["Completed"] += val.count
                else if (val.message === "LOAD_E") acc["Error"] += val.count
                

                if (index === arr.length - 1) {
                    acc["Total"] = acc["Completed"] + acc["Error"];
                }
                return acc;
            }, { "Completed": 0, "Error": 0, "Unzipped": 0, "Total": 0 });
            
            return { data: { healthStats } }
        }
    }, (err) => {
      
        return { error: err.message }
    })

}

export function getInstrumentType() {
    return get("/Optix/lookup/instrumenttype")
        .then(resp => {
            console.log("instruments resp", resp);
            const data = Object.values(resp.data).reduce((acc, val, ind) => {
                let tempObj = { key: val, value: val }
                acc.push(tempObj)
                return acc;
            }, [])
            return { data: data };
        }, err => {
            console.log("instruments resp err", err.message)
            return { error: err.message }
        });
}

export function getChartDataByAnalyzersType() {
    return get("/Optix/charts/analyzersperdevice")
        .then(resp => {
            console.log("resp", resp);
            if (resp.data?.length > 0) {
                const finalData = simplifyChartData(resp);
                const series = Object.keys(finalData.series).map(val => ({ name: val, data: finalData.series[val] }));
                
                return { data: { categories: finalData.categories, series } };
            }
        }, err => {
            return { error: err.message }
        })
}


export function getChartDataByInterumentType() {
    return get("/Optix/charts/zipfilesbyinstrument")
        .then(resp => {
            console.log("resp", resp);
            if (resp.data?.length > 0) {
                const finalData = simplifyChartData(resp);
                const series = Object.keys(finalData.series).map(val => ({ name: val, data: finalData.series[val] }));
                return { data: { categories: finalData.categories, series } };
            }
        }, err => {
            return { error: err.message }
        })
}


export function getChartDataByDeviceSize(){
    return get("/Optix/charts/sizeperdevice")
        .then(resp => {
            console.log("resp", resp);
            if (resp.data?.length > 0) {
                const finalData = simplifyChartData(resp);
                const series = Object.keys(finalData.series).map(val => ({ name: val, data: finalData.series[val] }));
                return { data: { categories: finalData.categories, series } };
            }
        }, err => {
            return { error: err.message }
        })
    
}
export function getChartDataByloadTime(){
    return get("/Optix/charts/averagefileloadtime")
        .then(resp => {
            console.log("resp", resp);
            if (resp.data?.length > 0) {
                const finalData = simplifyChartData(resp);
                const series = Object.keys(finalData.series).map(val => ({ name: val, data: finalData.series[val] }));
                return { data: { categories: finalData.categories, series } };
            }
        }, err => {
            return { error: err.message }
        })
        
}

export function getChartDataByErrorName() {
    return get("/charts/errorsbyerrorname")
        .then(resp => {
            console.log("resp", resp);
            if (resp.data?.length > 0) {
                const finalData = simplifyChartData(resp)
                const series = Object.keys(finalData.series).map(val => ({ name: val, data: finalData.series[val] }));
                return { data: { categories: finalData.categories, series } }
            }
        }, err => {
            console.log("instruments resp err", err.message)
            return { error: err.message }
        })

}

export function getChartDataByDay() {
    return get("/charts/errorsbyday")
        .then(resp => {
            console.log("resp", resp);
            if (resp.data?.length > 0) {
                const finalData = simplifyChartData(resp);
                const series = Object.keys(finalData.series).map(val => ({ name: val, data: finalData.series[val] }));
                return { data: { categories: finalData.categories, series } };
            }
        }, err => {
            return { error: err.message }
        })
}

function simplifyChartData(resp) {
    const finalData = resp.data.reduce((acc, val, ind, arr) => {
        const { date } = val;
        Object.keys(val).filter(value => value !== "date").forEach((item) => {
            if (acc.series.hasOwnProperty(item) && acc.series[item].length === ind) {
                acc.series[item] = [...acc.series[item], val[item]]
            } else if (acc.series.hasOwnProperty(item) && acc.series[item].length !== ind) {
                const diff = ind - acc.series[item].length;
                const arr = new Array(diff - 1).fill(0);
                acc.series[item] = [...arr, val[item]]
            }
            else {
                if (ind > 0) {
                    const arr = new Array(ind - 1).fill(0);
                    acc.series[item] = [...arr, val[item]]
                } else {
                    acc.series[item] = [val[item]]
                }

            }

        })
        acc.categories.push(date);
        return acc;

    }, { categories: [], series: {} })

    console.log(finalData)
    return finalData;
}
