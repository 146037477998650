import { FormControl, Input, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import moment from 'moment';
import react from 'react'
import Card from '../../../components/card/card';
import Datetime from '../../../components/datetime/datetime';
import Loader from '../../../components/loader/loader';
import Modal from '../../../components/modal/modal';
import StrippedTable from '../../../components/table/table';
import { getStatus, getSWVersions } from '../../../services/Vitros/contents-service';
import { lookupInstrumentType } from '../../../services/Vitros/stats-service';
import get from '../../../utils/http-utils';
import './contents.scss';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

class Contents extends react.Component {

    constructor(props) {
        super(props);
        this.state = {
            from: moment(Date.now()).startOf('day').format(),
            to: moment(Date.now()).format(),
            instruments: [],
            selectedInstruments: [],
            status: [],
            selectedStatus: [],
            selectedSerialNumber: "",
            serialNumbers: [],
            zipfilesTableData: { headers: [], data: [] },
            zfStatusTableData: { headers: [], data: [] },
            zfErrorsTableData: { headers: [], data: [] },
            showModal: false,
            modalRowIndex: -1,
            statusLoaded: false,
            instrumentsLoaded: false,
            instrumentErrMsg: "",
            statusErrMsg: "",
            zipFilesLoaded: true,
            pageCount: -1,
            currentPage: 1,
            paginationLoaded: false,
            paginationMessage: "",
            swVersions: [],
            swVersionErrMsg:'',
            swVersionsLoaded:false, // temp workaround
            selectedSWVersion:[]
        }

        this.selectInstruments = this.selectInstruments.bind(this);
        this.setFrom = this.setFrom.bind(this);
        this.setTo = this.setTo.bind(this);
        this.selectStatus = this.selectStatus.bind(this);
        this.selectSerialNumber = this.selectSerialNumber.bind(this);
        this.getZipfilesStatus = this.getZipfilesStatus.bind(this);
        this.showModal = this.showModal.bind(this);
        this.getZipFiles = this.getZipFiles.bind(this);
        this.fetchSWVersions = this.fetchSWVersions.bind(this);
        this.selectSWVersion = this.selectSWVersion.bind(this)
    }

    showModal(ind) {
        this.setState({ showModal: !this.state.showModal, modalRowIndex: ind });
    }

    setTo(to) {
        const endDate = moment(to.target.value).format()
        this.setState({ to: endDate });
        console.log(moment(to.target.value).format());
        this.getZipFiles({ endDate })
        // this.getHealthStats(this.state.from, endDate);
    }

    setFrom(from) {
        const startDate = moment(from.target.value).format();
        this.setState({ from: startDate });
        console.log(moment(from.target.value).format());
        this.getZipFiles({ startDate });

        // this.getHealthStats(startDate, this.state.to);
    }

    getPagination({ startDate = this.state.from, endDate = this.state.to,
        status = this.state.selectedStatus.join(","),
        serialNumber = this.state.selectedSerialNumber,
        instrumentType = this.state.instruments.join(","),
        swVersion= this.state.selectedSWVersion.join(","),
        pageSize = 20 } = {}) {
        this.setState({ paginationLoaded: false })
        get("/pagination/zipfiles",
            { "content-type": "application/json" },
            {
                startDate,
                endDate,
                instrumentType,
                serialNumber,
                status,
                pageSize,
                swVersion
            }
        ).then(res => {
            this.setState({ pageCount: res.data[0]?.count })
            console.log("count", res.data);
            this.setState({ paginationLoaded: true, paginationMessage: "" })
        }, err => {
            console.log(err)
            this.setState({ paginationLoaded: true, paginationMessage: "Could Load Pagination.(Message:" + err.message + ")" })
        })
    }

    getZipFiles({ startDate = this.state.from, endDate = this.state.to, status = this.state.selectedStatus.join(","), serialNumber = this.state.selectedSerialNumber,
        instrumentType = this.state.selectedInstruments.join(","), swVersion = this.state.selectedSWVersion.join(","), page = 1, pageSize = 100 } = {}) {
        this.setState({
            zipfilesTableData: { headers: [], data: [] }, zfStatusTableData: { headers: [], data: [] },
            zfErrorsTableData: { headers: [], data: [] }, zipFilesLoaded: false
        });
        let params = {
            startDate,
            endDate,
            instrumentType,
            page,
            pageSize,
            status: status,
            serialNumber: serialNumber ? serialNumber : "",
            swVersion
        }
        if (page === 1) this.getPagination(params);

        this.setState({ currentPage: page });
        // if (serialNumber) params["serialNumber"] = serialNumber;
        get("/zipfiles",
            { "content-type": "application/json" }, params
        ).then(resp => {
            console.log(resp);
            if (resp.data.length > 0) {
                const headers = Object.keys(resp.data[0]);
                const data = resp.data;
                const zipFiles = { headers, data };
                this.setState({ zipfilesTableData: zipFiles, zipFilesLoaded: true });
            } else {
                this.setState({ zipfilesTableData: { headers: [], data: [] }, zipFilesLoaded: true });
            }

        }, error => {
            console.log(error);
            this.setState({ zipFilesLoaded: true });
        })
    }

    getInstruments() {
        this.setState({ instrumentsLoaded: false });
        lookupInstrumentType().then(response => {
            if (response.hasOwnProperty('error')) {
                console.log("error reponse instrument lookup", response);
                this.setState({ instrumentsLoaded: true, instrumentErrMsg: "Could not load instruments, please try later.(Message:" + response.error + ")" });
            } else {
                console.log("success reponse instrument lookup", response);
                this.setState({ instrumentsLoaded: true, instruments: response.data });
            }
        })
    }

    selectInstruments(event) {
        this.setState({ selectedInstruments: event.target.value });
        this.getZipFiles({ instrumentType: event.target.value.join(",") })
    };

    selectStatus(event) {
        const status = event.target.value;
        this.setState({ selectedStatus: status });
        this.getZipFiles({ status: status.join(",") });
    };

    selectSWVersion(event) {
        const swVersion = event.target.value;
        console.log('sw version === ',swVersion,event.target.value)
        this.setState({ selectedSWVersion: swVersion });
        this.getZipFiles({ swVersion: swVersion.join(",") });
    };

    selectSerialNumber(event) {
        const serialNumber = event.target.value;
        this.setState({ selectedSerialNumber: serialNumber });
        this.getZipFiles({ serialNumber });
    };

    getStatus() {
        getStatus().then(({ data, error }) => {
            if (error) {
                this.setState({ statusErrMsg: "Could not load status, please try later.(Message:" + error.message + ")" })
            } else {
                this.setState({ status: data, selectedStatus: ["load_complete", "load_error"], statusLoaded: true });
                this.getZipFiles({ status: "load_complete,load_error" });
            }
        })
    }

    fetchSWVersions() {
        getSWVersions().then(({ data, error }) => {
            if (error) {
                this.setState({ swVersionErrMsg: "Could not load Software Versions, please try later.(Message:" + error.message + ")" })
            } else {
                this.setState({ swVersions: data, swVersionsLoaded:true });
                // this.getZipFiles({ status: "load_complete,load_error" });
            }
        })
    }

    getZipfilesStatus(data) {
        console.log(data)
        const sNumber = data["serial_number"];
        const zfId = data["zip_file_id"];
        const iType = data["instrument_type"];
        const zfName = data["zip_file_name"];
        get("/zipfiles/zipfile/status",
            { "content-type": "application/json" }, {
            serialNumber: sNumber ? sNumber : "",
            instrumentType: iType ? iType : "",
            zipFileId: zfId ? zfId : ""
        }
        ).then(resp => {
            console.log("zipfile status --------------------", resp.data)
            if (resp.data.length > 0) {
                const headers = Object.keys(resp.data[0]);
                const data = resp.data;
                const statusTD = { headers, data };
                this.setState({ zfStatusTableData: statusTD });
            }
        })

        get("/zipfiles/errors",
            { "content-type": "application/json" }, {
            startDate: this.state.from,
            endDate: this.state.to,
            zipfileName: zfName ? zfName : "",
            zipfileId: zfId ? zfId : "",
            page: 1,
            pageSize: 100
        }).then(resp => {
            console.log("zipfile status --------------------", resp.data)
            if (resp.data.length > 0) {
                const headers = Object.keys(resp.data[0]);

                const data = resp.data.map((val, ind) => {
                    val["stack_trace_data"] = val["stack_trace"];
                    val["stack_trace"] = (
                        <span className="icon" onClick={() => this.showModal(ind)} title="Info">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-info" viewBox="0 0 16 16">
                                <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                            </svg>
                        </span>
                    )
                    return val;
                });
                const errors = { headers, data };
                this.setState({ zfErrorsTableData: errors });
            } else {
                this.setState({ zfErrorsTableData: { headers: [], data: [] } });
            }
        })
    }

    componentDidMount() {
        this.getInstruments();
        this.getStatus();
        this.fetchSWVersions();
    }

    render() {

        return (<>
            <div className="row mb-3">
                <div className="col-sm-6 col-md-6">
                    <Datetime title="Created Date" setTo={this.setTo} setFrom={this.setFrom} />
                </div>
                <div className="col-sm-3 col-md-3">
                    <Card title="Instrument Type">
                        {
                            this.state.instrumentsLoaded ? (
                                this.state.instrumentErrMsg ? <span className="alert-danger">{this.state.instrumentErrMsg}</span> :
                                    <FormControl className="custom-form-control">
                                        <InputLabel id="demo-mutiple-name-label">Instruments</InputLabel>
                                        <Select
                                            labelId="demo-mutiple-name-label"
                                            id="demo-mutiple-name"
                                            multiple
                                            value={this.state.selectedInstruments}
                                            onChange={this.selectInstruments}
                                            input={<Input />}
                                            MenuProps={MenuProps}
                                        >
                                            {this.state.instruments.map((item) => (
                                                <MenuItem key={item.key} value={item.value}>
                                                    {item.key}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                            )

                                : <div className="w-100 d-flex">
                                    <Loader />
                                </div>
                        }

                    </Card>
                </div>
                <div className="col-sm-3 col-md-3">
                    <Card title="Status">
                        {this.state.statusLoaded ?
                            <FormControl className="custom-form-control" >
                                <InputLabel id="demo-mutiple-name-label">Status</InputLabel>
                                <Select
                                    labelId="demo-mutiple-name-label"
                                    id="demo-mutiple-name"
                                    multiple
                                    value={this.state.selectedStatus}
                                    onChange={this.selectStatus}
                                    input={<Input />}
                                    MenuProps={MenuProps}
                                >
                                    {this.state.status.map((item) => (
                                        <MenuItem key={item.key} value={item.value}>
                                            {item.key}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            :
                            <div className="w-100 d-flex">
                                <Loader />
                            </div>
                        }

                    </Card>
                </div>
            </div>
            <div className="row mb-3">
            <div className="col-sm-3 col-md-3">
                    <Card title="SW Version">
                        {this.state.swVersionsLoaded ?
                            <FormControl className="custom-form-control" >
                                <InputLabel id="demo-mutiple-name-label">Software Version</InputLabel>
                                <Select
                                    labelId="demo-mutiple-name-label"
                                    id="demo-mutiple-name"
                                    multiple
                                    value={this.state.selectedSWVersion}
                                    onChange={this.selectSWVersion}
                                    // onChange={() => {}} //temp fix
                                    input={<Input />}
                                    MenuProps={MenuProps}
                                >
                                    {this.state.swVersions.map((item) => (
                                        <MenuItem key={item.key} value={item.value}>
                                            {item.key}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            :
                            <div className="w-100 d-flex">
                                <Loader />
                            </div>
                        }

                    </Card>
                </div>
                <div className="col-sm-3 col-md-3">
                    <Card title="Serial Number Search">
                        <TextField onChange={this.selectSerialNumber} className="w-100" id="standard-basic" label="Serial Number value" />
                    </Card>
                </div>
            </div>

            <div className="mb-3">

                <Card title="Zip file Status">
                    {this.state.zipFilesLoaded ?
                        <StrippedTable
                            paginationCallout={this.getZipFiles}
                            message={this.state.statusLoaded ? "" : "Waiting for status to load."}
                            {...this.state.zipfilesTableData}
                            rowClick={this.getZipfilesStatus}
                            pageCount={this.state.pageCount}
                            currentPage={this.state.currentPage}
                            paginationLoaded={this.state.paginationLoaded}
                            rowStyles={{ cursor: "pointer" }} /> : <Loader />}
                </Card>
            </div>

            <div className="mb-3">

                <Card title="Content file Status">
                    <StrippedTable {...this.state.zfStatusTableData}/>
                </Card>

            </div>

            <div className="mb-3">

                <Card title="Errors">
                    <StrippedTable {...this.state.zfErrorsTableData} >
                        <Modal showModal={this.state.showModal} closeModal={this.showModal}
                            modalBody={this.state.modalRowIndex >= 0 ? this.state.zfErrorsTableData.data[this.state.modalRowIndex]["stack_trace_data"] : "Improper Index selected"}
                            modalTitle={"Stack Trace"}
                        ></Modal>
                    </StrippedTable>
                </Card>

            </div>
        </>)
    }
}

export default Contents;