import react from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official';
require("highcharts/modules/exporting")(Highcharts);

class BasicLineChart extends react.Component{

    constructor(props){
        super(props);

        const load = function() {

            let points0 = [];
            let points1 = [];

            if (this.series.length > 1) {
                points0 = this.series[0].data;
                points1 = this.series[1].data;
            }

            //proceed only if lenth of points0 and points1 are equal so that error cannot find dataLabel of undefined is not thrown
            if (points0.length === points1.length) {

                // Look for points which x positions are close and move them
                points0.forEach(function(point, i) {
                    let { x, y } = point.dataLabel.attr();
                    let { x:x1, y:y1 } = points1[i].dataLabel.attr();
                    
                    // Add y offsets
                    y += 20;
                    y1 += 2;
                    // Set new positions
                    points1[i].dataLabel.attr({x: x1, y: y1});
                    point.dataLabel.attr({x: x, y: y});
                });
            }
          }

        this.state = {
            options: {
            chart: {
                type: 'line',
                events: {
                    load: load
                }
              },
              title: {
                text: this.props.title ? this.props.title : "title goes here"
            },
            subtitle: {
                text: ''
            },
            xAxis: {
                categories: this.props.categories,
                crosshair: true,
                title: {
                    text: this.props.xAxisTitle
                },
                reversed:true

            },
            yAxis: {
                min: 0,
                title: {
                    text: this.props.yAxisTitle
                }
            },
            tooltip: {
                headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                    '<td style="padding:0"><b>{point.y}</b></td></tr>',
                footerFormat: '</table>',
                shared: true,
                followPointer: true,
                useHTML: true
            },
              plotOptions: {
                column: {
                    pointPadding: 0.1,
                    borderWidth: 0
                },
                line: {
                  dataLabels: {
                    enabled: true,
                    allowOverlap: true
                  },
                  enableMouseTracking: true
                }
              },
              series: this.props.series
            }
        }

    }
    render() {
        return (
            <HighchartsReact
                highcharts={Highcharts}
                options={this.state.options}
            />
        )
    }
}
export default BasicLineChart;