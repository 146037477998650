import {
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import moment from "moment";
import react, {useState} from "react";
import Card from "../../../components/card/card";
import Datetime from "../../../components/datetime/datetime";
import Loader from "../../../components/loader/loader";
import Modal from "../../../components/modal/modal";
import ModalDetailedStatus from "../../../components/modal/modalForDetailedStatus";
import StrippedTable from "../../../components/table/table";
import StrippedTableFileStatus from "../../../components/table/tableFileStatus";
import { getStatus} from "../../../services/Optix/optix-contents-service";
import { getInstrumentType } from "../../../services/Optix/optix-stats-service"; 

// import { getStatus } from '../../services/contents-service';
// import { lookupInstrumentType } from '../../services/stats-service';
import get from "../../../utils/http-utils";
import "./OptixContents.scss";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

class OptixContents extends react.Component {
  constructor(props) {
    super(props);
    this.state = {
      from: moment(Date.now()).startOf("day").format(),
      to: moment(Date.now()).format(),
      instruments: [],
      selectedInstruments: [],
      status: [],
      selectedStatus: [],
      selectedSerialNumber: "",
      // serialNumber: "",
      zipFileId: "",
      // serialNumbers: [],
      zipfilesTableData: { headers: [], data: [] },
      filesStatusTableData: { headers: [], data: [] },
      zfStatusTableData: { headers: [], data: [] },
      zfErrorsTableData: { headers: [], data: [] },
      detailedStatusData: {headers: [], data: []},
      zfErrorsFlag: false,
      showModal: false,
      showModalForDetailedStatus: false,
      modalRowIndex: -1,
      statusLoaded: false,
      instrumentsLoaded: false,
      instrumentErrMsg: "",
      statusErrMsg: "",
      zipFilesLoaded: true,
      filesStatusLoaded: false,
      filesStatusFlag: false,
      detailedStatusLoaded: false,
      pageCount: -1,
      pageCountFileStatus: -1,
      currentPage: 1,
      currentPageFileStatus: 1,
      paginationLoaded: false,
      paginationLoadedFileStatus: false,
      paginationMessage: "",
      rowClickCount: 0
      
    };

    this.selectInstruments = this.selectInstruments.bind(this);
    this.setFrom = this.setFrom.bind(this);
    this.setTo = this.setTo.bind(this);
    this.selectStatus = this.selectStatus.bind(this);

    this.selectSerialNumber = this.selectSerialNumber.bind(this);
    this.getZipfilesStatus = this.getZipfilesStatus.bind(this);
    this.showModal = this.showModal.bind(this);
    this.showModalForDetailedStatus = this.showModalForDetailedStatus.bind(this);
    this.getZipFiles = this.getZipFiles.bind(this);
    this.getFilesStatusTable = this.getFilesStatusTable.bind(this);
    // this.getXmlFiles = this.getXmlFiles.bind(this);
  }

  showModal(ind) {
    this.setState({ showModal: !this.state.showModal, modalRowIndex: ind });
  }

  showModalForDetailedStatus(ind) {
    this.setState({ showModalForDetailedStatus: !this.state.showModalForDetailedStatus, modalRowIndexForDetailedStatus: ind });
  }

  setTo(to) {
    const endDate = moment(to.target.value).format();
    this.setState({ to: endDate });
    console.log(moment(to.target.value).format());
    this.getZipFiles({ endDate });
    // this.getFilesStatusTable({endDate});
    // this.getHealthStats(this.state.from, endDate);
  }

  setFrom(from) {
    const startDate = moment(from.target.value).format();
    this.setState({ from: startDate });
    console.log(moment(from.target.value).format());
    this.getZipFiles({ startDate });
    // this.getFilesStatusTable({startDate});
    // this.getHealthStats(startDate, this.state.to);
  }

  selectStatus(event) {
    const status = event.target.value;
    this.setState({ selectedStatus: status });
    this.getZipFiles({ status: status.join(",") });
  }

  selectInstruments(event) {
    this.setState({ selectedInstruments: event.target.value });
    this.getZipFiles({ instrumentType: event.target.value.join(",") })
  };

  selectSerialNumber(event) {
    const serialNumber = event.target.value;
    this.setState({ selectedSerialNumber: serialNumber });
    this.getZipFiles({ serialNumber });
  };


  getStatus() {
    getStatus().then(({ data, error }) => {
      if (error) {
        this.setState({
          statusErrMsg:
            "Could not load status, please try later.(Message:" +
            error.message +
            ")",
        });
      } else {
        this.setState({ status: data, statusLoaded: true });
        // this.setState({})
        // this.getZipFiles({ status: data});
      }
    });
  }

  getInstruments() {
    this.setState({ instrumentsLoaded: false });
    getInstrumentType().then(response => {
        if (response.hasOwnProperty('error')) {
            console.log("error reponse instrument lookup", response);
            this.setState({ instrumentsLoaded: true, instrumentErrMsg: "Could not load instruments, please try later.(Message:" + response.error + ")" });
        } else {
            console.log("success reponse instrument lookup", response);
            this.setState({ instrumentsLoaded: true, instruments: response.data });
        }
    })
}


  getPagination({
    startDate = this.state.from,
    endDate = this.state.to,
    status = this.state.selectedStatus.join(","),
    serialNumber = this.state.selectedSerialNumber,
    instrumentType = this.state.instruments.join(","),
    pageSize = 20,
  } = {}) {
    this.setState({ paginationLoaded: false });
    get(
      "/Optix/pagination/zipfiles",
      { "content-type": "application/json" },
      {
        startDate,
        endDate,
        pageSize,
        serialNumber: serialNumber ? serialNumber : "",
        status,
        instrumentType
      }
    ).then(
      (res) => {
        this.setState({ pageCount: res.data[0]?.count });
        console.log("count", res.data);
        this.setState({ paginationLoaded: true, paginationMessage: "" });
      },
      (err) => {
        console.log(err);
        this.setState({
          paginationLoaded: true,
          paginationMessage:
            "Could Load Pagination.(Message:" + err.message + ")",
        });
      }
    );
  }
  getPaginationFileStatus({ startDate = this.state.from, endDate = this.state.to,
    // status = this.state.selectedStatus.join(","),
    zipFileId = this.state.zipFileId,
    pageSizeFileStatus = 100 } = {}) {
    console.log("page countttt:  "+this.state.pageCountFileStatus);
    this.setState({ paginationLoadedFileStatus: false })
    get("/Optix/pagination/filesStatus",
        { "content-type": "application/json" },
        {
            startDate,
            endDate,
            pageSizeFileStatus,
            zipFileId
        }
    ).then(res => {
        this.setState({ pageCountFileStatus: res.data[0]?.count})
        console.log("count", res.data);
        
        this.setState({ paginationLoadedFileStatus: true, paginationMessage: "" })
    }, err => {
        console.log(err)
        this.setState({ paginationLoadedFileStatus: true, paginationMessage: "Could Load Pagination.(Message:" + err.message + ")" })
    })
  }


  getZipFiles({
    startDate = this.state.from,
    endDate = this.state.to,
    status = this.state.selectedStatus.join(","),
    serialNumber = this.state.selectedSerialNumber,
    instrumentType = this.state.selectedInstruments.join(","),
    page = 1,
    pageSize = 100,
  } = {}) {
    this.setState({
      zipfilesTableData: { headers: [], data: [] },
      zfStatusTableData: { headers: [], data: [] },
      zfErrorsTableData: { headers: [], data: [] },
      zipFilesLoaded: false,
    });
    this.setState({filesStatusFlag: false })
    this.setState({zfErrorsFlag: false })
    let params = {
      startDate,
      endDate,
      page,
      pageSize,
      status: status,
      serialNumber: serialNumber ? serialNumber : "",
      instrumentType
    };
    if (page === 1) this.getPagination(params);
    console.log("serial number::::: "+serialNumber);
    this.setState({ currentPage: page });
    

    // if (serialNumber) params["serialNumber"] = serialNumber;
    get(
      "/Optix/zipfiles",
      { "content-type": "application/json" },
      params
    ).then(
      (resp) => {
        console.log(resp);
        if (resp.data.length > 0) {
          const headers = Object.keys(resp.data[0]);
          const data = resp.data.map((val, ind) => {
            // val["detailed_status_data"] = val["detailed_status"];
            
            val["detailed_status"] = (
              <span
                className="icon"
                onClick={() => this.showModalForDetailedStatus(ind)}
                title="Info"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-info"
                  viewBox="0 0 16 16"
                >
                  <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                </svg>
              </span>
            );
            return val;
          });
          const zipFiles = { headers, data };
          this.setState({ zipfilesTableData: zipFiles, zipFilesLoaded: true });
          // console.log("Detailed status::::: "+this.state.zipfilesTableData.data[
          //   0
          // ]["detailed_status_data"])
        } else {
          this.setState({
            zipfilesTableData: { headers: [], data: [] },
            zipFilesLoaded: true,
          });
        }
      },
      (error) => {
        console.log(error);
        this.setState({ zipFilesLoaded: true });
      }
    );
  }

  getFilesStatusTable({
    startDate = this.state.from,
    endDate = this.state.to,
    zipFileId = this.state.zipFileId,
    pageFileStatus = 1,
    pageSizeFileStatus = 100,
  } = {}) {
    this.setState({
      filesStatusTableData: { headers: [], data: [] },
      filesStatusLoaded: false,
    });
    
    let params = {
        startDate,
        endDate,
        zipFileId,
        pageFileStatus,
        pageSizeFileStatus,
    };
    console.log("::: PageFIleStatus "+pageFileStatus)
    if (pageFileStatus === 1) this.getPaginationFileStatus(params);

    this.setState({ currentPageFileStatus: pageFileStatus });
    console.log("CUrrent Page:  "+this.state.currentPageFileStatus)
    
    
    get(
        "/Optix/filesStatus",
        { "content-type": "application/json" },
        params
      ).then(
        (resp) => {
          console.log(resp);
          if (resp.data.length > 0) {
            const headers = Object.keys(resp.data[0]);
            const data = resp.data;
            const filesStatus = { headers, data };
            this.setState({
              filesStatusTableData: filesStatus,
              filesStatusLoaded: true,
            });
          } else {
            this.setState({
              filesStatusTableData: { headers: [], data: [] },
              filesStatusLoaded: true,
            });
          }
        },
        (error) => {
          console.log(error);
          this.setState({ filesStatusLoaded: true });
        }
      );
    }


  getZipfilesStatus(data, pageFileStatus = 1, pageSizeFileStatus = 100) {
    
    console.log(data);
    // const sNumber = data["serial_number"];
    const zfId = data["zip_file_id"];
    // const iType = data["instrument_type"];
    const zfName = data["zip_file_name"];
    const status_msg = data["status_msg"];
    this.setState({zfErrorsFlag: false })
    if(zfId != undefined){
        this.setState({zipFileId: zfId})
    }
    if(zfId == this.state.zipFileId){
      this.setState({rowClickCount: this.state.rowClickCount + 1})
      console.log("ssInside: "+this.state.zipFileId);
    }

    else{
      this.setState({rowClickCount: 0})
    }
    

    get(
      "/Optix/zipfiles/detailed-status",
      { "content-type": "application/json" },
      {
        zipfileName: zfName!=undefined ? zfName : this.state.zipfileName,
        zipFileId: zfId!=undefined ? zfId : this.state.zipFileId,
      }
    ).then(
      (resp) => {
        console.log("resp:::"+resp);
        if (resp.data.length > 0) {
          const headers = Object.keys(resp.data[0]);
          const data = resp.data.map((val, ind) => {
            return val;
          }
          );
          // console.log("---data---")
          // console.log(data[0]['new_status'])
          const detailedStatus = { headers, data };
          this.setState({
            detailedStatusData: detailedStatus,
            detailedStatusLoaded: true,
          });
        } else {
          console.log("inside else")
          this.setState({
            detailedStatusData: { headers: [], data: [] },
            detailedStatusLoaded: true,
          });
        }
      },
      (error) => {
        console.log(error);
        this.setState({ detailedStatusLoaded: true });
      }
    );
    console.log("---data---")
      console.log(this.state.detailedStatusData.data);
      
   
    if(status_msg == "LOAD_E"){
      this.setState({zfErrorsFlag: true })
      this.setState({filesStatusFlag: false })
    get(
      "/Optix/zipfiles/errors",
      { "content-type": "application/json" },
      {
        startDate: this.state.from,
        endDate: this.state.to,
        zipfileName: zfName!=undefined ? zfName : this.state.zipfileName,
        zipFileId: zfId!=undefined ? zfId : this.state.zipFileId,
        page: 1,
        pageSize: 100,
        
      }
    ).then((resp) => {
      console.log("zipfile status --------------------", resp.data);
      if (resp.data.length > 0) {
        const headers = Object.keys(resp.data[0]);

        const data = resp.data.map((val, ind) => {
          val["stack_trace_data"] = val["stack_trace"];

          val["stack_trace"] = (
            <span
              className="icon"
              onClick={() => this.showModal(ind)}
              title="Info"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                className="bi bi-info"
                viewBox="0 0 16 16"
              >
                <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
              </svg>
            </span>
          );
          return val;
        });
        const errors = { headers, data };
        this.setState({ zfErrorsTableData: errors,
          zfErrorsFlag: true});
      } else {
        this.setState({ zfErrorsTableData: { headers: [], data: [] } });
      }
    });
  }
    if(status_msg == "LOAD_C"){
      
       let params = {
        
            startDate: this.state.from,
            endDate: this.state.to,
            zipFileId: zfId!=undefined ? zfId : this.state.zipFileId,
            pageFileStatus,
            pageSizeFileStatus,
    
        };
        
    // console.log(pageFileStatus+"::::::Page file")
    if (pageFileStatus === 1) this.getPaginationFileStatus(params);
    this.setState({filesStatusFlag: true })
    this.setState({zfErrorsFlag: false })
    this.setState({ currentPageFileStatus: pageFileStatus });
    // if (serialNumber) params["serialNumber"] = serialNumber;
    get(
      "/Optix/filesStatus",
      { "content-type": "application/json" },
      params
    ).then(
      (resp) => {
        console.log(resp);
        if (resp.data.length > 0) {
          const headers = Object.keys(resp.data[0]);
          const data = resp.data;
          const filesStatus = { headers, data };
          this.setState({
            filesStatusTableData: filesStatus,
            filesStatusLoaded: true,
            filesStatusFlag: true
          });
        } else {
          this.setState({
            filesStatusTableData: { headers: [], data: [] },
            filesStatusLoaded: true,
          });
        }
      },
      (error) => {
        console.log(error);
        this.setState({ filesStatusLoaded: true });
      }
    );
    }

  }
  componentDidMount() {

    // this.getFilesStatusTable();
    this.getInstruments();
    this.getZipFiles();
    this.getStatus();
    
  }

  render() {
    return (
      <>
        <div className="row mb-3">
          <div className="col-sm-6 col-md-6">
            <Datetime
              title="Created Date"
              setTo={this.setTo}
              setFrom={this.setFrom}
            />
          </div>

          <div className="col-sm-3 col-md-3">
                  <Card title="Instrument Type">
                      {
                          this.state.instrumentsLoaded ? (
                              this.state.instrumentErrMsg ? <span className="alert-danger">{this.state.instrumentErrMsg}</span> :
                                  <FormControl className="custom-form-control">
                                      <InputLabel id="demo-mutiple-name-label">Instruments</InputLabel>
                                      <Select
                                          labelId="demo-mutiple-name-label"
                                          id="demo-mutiple-name"
                                          multiple
                                          value={this.state.selectedInstruments}
                                          onChange={this.selectInstruments}
                                          input={<Input/>}
                                          MenuProps={MenuProps}
                                      >
                                          {this.state.instruments.map((item) => (
                                              <MenuItem key={item.key} value={item.value}>
                                                  {item.key}
                                              </MenuItem>
                                          ))}
                                      </Select>
                                  </FormControl>
                          )

                              : <div className="w-100 d-flex">
                                  <Loader />
                              </div>
                      }

                  </Card>
              </div>

          <div className="col-sm-3 col-md-3">
            <Card title="Status">
              {this.state.statusLoaded ? (
                <FormControl className="custom-form-control">
                  <InputLabel id="demo-mutiple-name-label">Status</InputLabel>
                  <Select
                    labelId="demo-mutiple-name-label"
                    id="demo-mutiple-name"
                    multiple
                    value={this.state.selectedStatus}
                    onChange={this.selectStatus}
                    input={<Input />}
                    //   MenuProps={MenuProps}
                  >
                    {this.state.status.map((item) => (
                      <MenuItem key={item.key} value={item.value}>
                        {item.key}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : (
                <div className="w-100 d-flex">
                  <Loader />
                </div>
              )}
            </Card>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-sm-3 col-md-3">
            <Card title="Serial Number Search">
              <TextField
                onChange={this.selectSerialNumber}
                className="w-100"
                id="standard-basic"
                label="Serial Number value"
              />
            </Card>
          </div>
        </div>

        <div className="mb-3">
          <Card title="Zip file Status">
            
            {this.state.zipFilesLoaded ? (
              <StrippedTable
                paginationCallout={this.getZipFiles}
                message={
                  this.state.statusLoaded ? "" : "Waiting for status to load."
                }
                {...this.state.zipfilesTableData}
                rowClick={this.getZipfilesStatus}
                pageCount={this.state.pageCount}
                currentPage={this.state.currentPage}
                paginationLoaded={this.state.paginationLoaded}
                rowStyles={{ cursor: "pointer" }}
              >
                <ModalDetailedStatus
                showModal={this.state.showModalForDetailedStatus}
                closeModal={this.showModalForDetailedStatus}
                modalBody={
                  this.state.detailedStatusLoaded?
                  this.state.detailedStatusData.data[0]['new_status']
                  :""
                }
                modalTitle={"Detailed Status"}
              ></ModalDetailedStatus>
                </StrippedTable>
            ) : (
              <Loader />
            )}
          </Card>
        </div>
        {this.state.filesStatusFlag && this.state.rowClickCount %2 == 0? (
        <div className="mb-3">
          <Card title="File Status">
            
            {this.state.filesStatusLoaded ? (
              <StrippedTableFileStatus
                paginationCallout={this.getFilesStatusTable}
                {...this.state.filesStatusTableData}
                pageCount={this.state.pageCountFileStatus}
                currentPage={this.state.currentPageFileStatus}
                paginationLoaded={this.state.paginationLoadedFileStatus}
              />
            ) : (
              <Loader/>
            )
          }
          </Card>
        </div>
        ): (
          <div/>
        )}
        {this.state.zfErrorsFlag ? (
        <div className="mb-3">
          <Card title="Errors">
            <StrippedTable {...this.state.zfErrorsTableData}>
              <Modal
                showModal={this.state.showModal}
                closeModal={this.showModal}
                modalBody={
                  this.state.modalRowIndex >= 0
                    ? this.state.zfErrorsTableData.data[
                        this.state.modalRowIndex
                      ]["stack_trace_data"]
                    : "Improper Index selected"
                }
                modalTitle={"Stack Trace"}
              ></Modal>
            </StrippedTable>
          </Card>
        </div>
        ) :(
          <div/>
        )}
      </>
    );
  }
}

export default OptixContents;
