export const navObjects = {
    Vitros: [
        { name: "ETL Dashboard", route: "/Vitros" },
        { name: "ETL File status", route: "/Vitros/contents" },
        { name: "ETL Errors", route: "/Vitros/errors" },
        { name: "Datalake File status", route: "/Vitros/datalakeContents" },
        { name: "DB File Management", route: "/Vitros/dbFileManagement" },
        { name: "Redshift File status", route: "/Vitros/redshiftContents"},
      ],

      Vision: [
        { name: "Vision ETL Dashboard", route: "/Vision" },
        { name: "ETL File status", route: "/Vision/contents" },
        { name: "ETL Errors", route: "/Vision/errors" },
        { name: "Redshift Load", route: "/Vision/redshiftLoad" }
      ],

      Optix: [
        { name: "Optix ETL Dashboard", route: "/Optix" },
        { name: "ETL File status", route: "/Optix/contents" },
        { name: "ETL Errors", route: "/Optix/errors" },
      ]
}