import { ConsoleLogger } from "@aws-amplify/core";
import {
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import moment from "moment";
import react, { useState } from "react";
import Card from "../../../components/card/card";
import Datetime from "../../../components/datetime/datetime";
import Loader from "../../../components/loader/loader";
import ModalDetailedStatus from "../../../components/modal/modalForDetailedStatus";
import StrippedTable from "../../../components/table/table";
import StrippedTableFileStatus from "../../../components/table/tableFileStatus";
import { getRedshiftLoadStatus } from "../../../services/Vision/vision-redshift-load-service";
import { getInstrumentType } from "../../../services/Vision/vision-stats-service";

// import { getRedshiftLoadStatus } from '../../services/contents-service';
// import { lookupInstrumentType } from '../../services/stats-service';
import get from "../../../utils/http-utils";
import "./VisionRedshiftLoad.scss";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

class VisionRedshiftLoad extends react.Component {
  constructor(props) {
    super(props);
    this.state = {
      from: moment(Date.now()).startOf("day").format(),
      to: moment(Date.now()).format(),
      instruments: [],
      selectedInstruments: [],
      status: [],
      selectedStatus: [],
      selectedSerialNumber: "",
      // serialNumber: "",
      jrId: "",
      // serialNumbers: [],
      redshiftLoadTableData: { headers: [], data: [] },
      filesStatusTableData: { headers: [], data: [] },
      zfStatusTableData: { headers: [], data: [] },
      zfErrorsTableData: { headers: [], data: [] },
      detailedStatusData: { headers: [], data: [] },
      zfErrorsFlag: false,
      showModal: false,
      showModalForZipFileNames: false,
      modalRowIndex: -1,
      statusLoaded: false,
      instrumentsLoaded: false,
      instrumentErrMsg: "",
      statusErrMsg: "",
      zipFilesLoaded: true,
      filesStatusLoaded: false,
      filesStatusFlag: false,
      detailedStatusLoaded: false,
      pageCount: -1,
      pageCountFileStatus: -1,
      currentPage: 1,
      currentPageFileStatus: 1,
      paginationLoaded: false,
      paginationLoadedFileStatus: false,
      paginationMessage: "",
      rowClickCount: 0

    };

    this.selectInstruments = this.selectInstruments.bind(this);
    this.setFrom = this.setFrom.bind(this);
    this.setTo = this.setTo.bind(this);
    this.selectStatus = this.selectStatus.bind(this);

    this.selectSerialNumber = this.selectSerialNumber.bind(this);
    this.showModal = this.showModal.bind(this);
    this.showModalForZipFileNames = this.showModalForZipFileNames.bind(this);
    this.getRsFeed = this.getRsFeed.bind(this);
    this.getRsfeedJobRun = this.getRsfeedJobRun.bind(this);

    // this.getXmlFiles = this.getXmlFiles.bind(this);
  }

  showModal(ind) {
    this.setState({ showModal: !this.state.showModal, modalRowIndex: ind });
  }

  showModalForZipFileNames(ind) {
    //console.log('ind: ');
    this.setState({ showModalForZipFileNames: !this.state.showModalForZipFileNames, modalRowIndexForDetailedStatus: ind });
    this.getRsfeedZipfiles(ind);
  }

  setTo(to) {
    const endDate = moment(to.target.value).format();
    this.setState({ to: endDate });
    //console.log(moment(to.target.value).format());
    this.getRsFeed({ endDate });
    // this.getRsfeedJobRun({endDate});
    // this.getHealthStats(this.state.from, endDate);
  }

  setFrom(from) {
    const startDate = moment(from.target.value).format();
    this.setState({ from: startDate });
    //console.log(moment(from.target.value).format());
    this.getRsFeed({ startDate });
    // this.getRsfeedJobRun({startDate});
    // this.getHealthStats(startDate, this.state.to);
  }

  selectStatus(event) {
    this.setState({ selectedStatus: event.target.value });
    this.getRsFeed({ status: event.target.value.join(",") });
  }

  selectInstruments(event) {
    this.setState({ selectedInstruments: event.target.value });
    this.getRsFeed({ instrumentType: event.target.value.join(",") })
  };

  selectSerialNumber(event) {
    const serialNumber = event.target.value;
    this.setState({ selectedSerialNumber: serialNumber });
    this.getRsfeedJobRun({ serialNumber: serialNumber });
  };


  getRedshiftLoadStatus() {
    this.setState({ statusLoaded: false });
    getRedshiftLoadStatus().then(response => {
      if (response.hasOwnProperty('error')) {
        this.setState({
          statusErrMsg:
            "Could not load status, please try later.(Message:" +
            response.error +
            ")",
        });
      } else {
        this.setState({ status: response.data, statusLoaded: true });
      }
    });
  }

  getInstruments() {
    this.setState({ instrumentsLoaded: false });
    getInstrumentType().then(response => {
      if (response.hasOwnProperty('error')) {
        //console.log("error reponse instrument lookup", response);
        this.setState({ instrumentsLoaded: true, instrumentErrMsg: "Could not load instruments, please try later.(Message:" + response.error + ")" });
      } else {
        //console.log("success reponse instrument lookup", response);
        this.setState({ instrumentsLoaded: true, instruments: response.data });
      }
    })
  }


  getPagination({
    startDate = this.state.from,
    endDate = this.state.to,
    status = this.state.selectedStatus.join(","),
    serialNumber = this.state.selectedSerialNumber,
    instrumentType = this.state.instruments.join(","),
    pageSize = 20,
  } = {}) {
    this.setState({ paginationLoaded: false });
    get(
      "/Vision/pagination/rsfeed",
      { "content-type": "application/json" },
      {
        startDate,
        endDate,
        pageSize,
        status
      }
    ).then(
      (res) => {
        this.setState({ pageCount: res.data[0]?.count });
        //console.log("count", res.data);
        this.setState({ paginationLoaded: true, paginationMessage: "" });
      },
      (err) => {
        //console.log(err);
        this.setState({
          paginationLoaded: true,
          paginationMessage:
            "Could Load Pagination.(Message:" + err.message + ")",
        });
      }
    );
  }


  getPaginationJobRun({ startDate = this.state.from, endDate = this.state.to,
    // status = this.state.selectedStatus.join(","),
    jobRunId = this.state.jobRunId,
    serialNumber = this.state.selectedSerialNumber,
    pageSizeFileStatus = 100 } = {}) {
    //console.log("page countttt:  " + this.state.pageCountFileStatus);
    this.setState({ paginationLoadedFileStatus: false })
    get("/Vision/pagination/rsfeed/getjobrun",
      { "content-type": "application/json" },
      {
        startDate,
        endDate,
        serialNumber,
        pageSizeFileStatus,
        jobRunId
      }
    ).then(res => {
      //console.log("/vision/pagination/getjobrun", res.data);
      this.setState({ pageCountFileStatus: res.data[0]?.count })

      //this.setState({currentPageFileStatus: })
      this.setState({ paginationLoadedFileStatus: true, paginationMessage: "" })
    }, err => {
      //console.log(err)
      this.setState({ paginationLoadedFileStatus: true, paginationMessage: "Could Load Pagination.(Message:" + err.message + ")" })
    })
  }


  getRsFeed({
    startDate = this.state.from,
    endDate = this.state.to,
    status = this.state.selectedStatus.join(","),
    serialNumber = this.state.selectedSerialNumber,
    instrumentType = this.state.selectedInstruments.join(","),
    page = 1,
    pageSize = 100,
  } = {}) {
    this.setState({
      redshiftLoadTableData: { headers: [], data: [] },
      zfStatusTableData: { headers: [], data: [] },
      zfErrorsTableData: { headers: [], data: [] },
      zipFilesLoaded: false,
    });
    this.setState({ filesStatusFlag: false })
    this.setState({ zfErrorsFlag: false })
    let params = {
      startDate,
      endDate,
      page,
      pageSize,
      status: status
    };
    if (page === 1) this.getPagination(params);
    //console.log("serial number::::: " + serialNumber);
    this.setState({ currentPage: page });


    // if (serialNumber) params["serialNumber"] = serialNumber;
    get(
      "/Vision/rsfeed",
      { "content-type": "application/json" },
      params
    ).then(
      (resp) => {
        //console.log(resp);
        if (resp.data.length > 0) {
          const headers = Object.keys(resp.data[0]);
          const data = resp.data.map((val, ind) => {
            // val["detailed_status_data"] = val["detailed_status"];

            return val;
          });
          const zipFiles = { headers, data };
          this.setState({ redshiftLoadTableData: zipFiles, zipFilesLoaded: true });
          // console.log("Detailed status::::: "+this.state.redshiftLoadTableData.data[
          //   0
          // ]["detailed_status_data"])
        } else {
          this.setState({
            redshiftLoadTableData: { headers: [], data: [] },
            zipFilesLoaded: true,
          });
        }
      },
      (error) => {
        //console.log(error);
        this.setState({ zipFilesLoaded: true });
      }
    );
  }

  getRsfeedJobRun(data, pageFileStatus = 1, pageSizeFileStatus = 100) {
    // console.log('get rsfeed job run');
    //console.log(data);
    if (data['pageFileStatus']){
      pageFileStatus = data['pageFileStatus'];
    }
    const serialNumber = data["serialNumber"];
    // console.log(serialNumber);
    // const iType = data["instrument_type"];
    const status_msg = data["status_msg"];
    this.setState({ zfErrorsFlag: false })

    if (data["job_run_id"] != undefined) {
      this.setState({ jrId: data["job_run_id"] })
    }

    // console.log('jrid');
    // console.log(this.state.jrId);

    let params = {

      startDate: this.state.from,
      endDate: this.state.to,
      jobRunId: data["job_run_id"] != undefined ? data["job_run_id"] : this.state.jrId,
      serialNumber: serialNumber,
      pageFileStatus,
      pageSizeFileStatus,

    };

    // console.log(pageFileStatus+"::::::Page file")
    if (pageFileStatus === 1) this.getPaginationJobRun(params);
    this.setState({ filesStatusFlag: true })
    this.setState({ zfErrorsFlag: false })
    this.setState({ currentPageFileStatus: pageFileStatus });
    // if (serialNumber) params["serialNumber"] = serialNumber;
    get(
      "/Vision/rsfeed/getjobrun",
      { "content-type": "application/json" },
      params
    ).then(
      (resp) => {
        //console.log(resp);
        if (resp.data.length > 0) {
          const headers = Object.keys(resp.data[0]);
          const data = resp.data;
          const filesStatus = { headers, data };
          this.setState({
            filesStatusTableData: filesStatus,
            filesStatusLoaded: true,
            filesStatusFlag: true
          });
        } else {
          this.setState({
            filesStatusTableData: { headers: [], data: [] },
            filesStatusLoaded: true,
          });
        }
      },
      (error) => {
        //console.log(error);
        this.setState({ filesStatusLoaded: true });
      }
    );

  }


  getRsfeedZipfiles(data, pageFileStatus = 1, pageSizeFileStatus = 100) {

    var jobRunId = this.state.jrId;

    const serialNumber = data["serial_number"];

    // console.log(jobRunId);
    // console.log(serialNumber);

    // if (jobRunId == this.state.jobRunId) {
    //   //this.setState({ rowClickCount: this.state.rowClickCount + 1 })
    //   //console.log("ssInside: " + this.state.jobRunId);
    // }
    // else {
    //   this.setState({ rowClickCount: 0 })
    // }

    // console.log(pageFileStatus+"::::::Page file")

    this.setState({ filesStatusFlag: true })
    this.setState({ zfErrorsFlag: false })
    this.setState({ currentPageFileStatus: pageFileStatus });
    // if (serialNumber) params["serialNumber"] = serialNumber;
    get(
      "/Vision/rsfeed/getzipfiles",
      { "content-type": "application/json" },
      {
        serialNumber: serialNumber,
        jobRunId: jobRunId
      }
    ).then(
      (resp) => {
        //console.log("get zip files resp:::" + resp);
        for (var x in resp) {
          // console.log(x);
          // console.log(resp[x]);
        }
        if (resp.data.length > 0) {
          const headers = Object.keys(resp.data[0]);
          const data = resp.data.map((val, ind) => {
            return val;
          }
          );
          //console.log("---get zip files data---")
          const detailedStatus = { headers, data };
          this.setState({
            detailedStatusData: detailedStatus,
            detailedStatusLoaded: true,
            fileStatusLoaded: true
          });
        } else {
          //console.log("inside else")
          this.setState({
            detailedStatusData: { headers: [], data: [] },
            detailedStatusLoaded: true,
          });
        }
      },
      (error) => {
        //console.log(error);
        this.setState({ detailedStatusLoaded: true });
      }
    );

  }



  componentDidMount() {

    // this.getRsfeedJobRun();
    this.getInstruments();
    this.getRedshiftLoadStatus();
    this.getRsFeed();

  }

  render() {
    return (
      <>
        <div className="row mb-3">
          <div className="col-sm-6 col-md-6">
            <Datetime
              title="Created Date"
              setTo={this.setTo}
              setFrom={this.setFrom}
            />
          </div>

          <div className="col-sm-3 col-md-3">
            <Card title="Status">
              {this.state.statusLoaded ? (
                this.state.statusErrMsg ? <span className="alert-danger">{this.state.statusErrMsg}</span> :
                  <FormControl className="custom-form-control">
                    <InputLabel id="demo-mutiple-name-label">Status</InputLabel>
                    <Select
                      labelId="demo-mutiple-name-label"
                      id="demo-mutiple-name"
                      multiple
                      value={this.state.selectedStatus}
                      onChange={this.selectStatus}
                      input={<Input />}
                      MenuProps={MenuProps}
                    >
                      {this.state.status.map((item) => (
                        <MenuItem key={item.key} value={item.value}>
                          {item.key}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
              ) : (
                <div className="w-100 d-flex">
                  <Loader />
                </div>
              )}
            </Card>
          </div>
        </div>

        <div className="mb-3">
          <Card title="Redshift Status">

            {this.state.zipFilesLoaded ? (
              <StrippedTable
                paginationCallout={this.getRsFeed}
                message={
                  this.state.statusLoaded ? "" : "Waiting for status to load."
                }
                {...this.state.redshiftLoadTableData}
                rowClick={this.getRsfeedJobRun}
                pageCount={this.state.pageCount}
                currentPage={this.state.currentPage}
                paginationLoaded={this.state.paginationLoaded}
                rowStyles={{ cursor: "pointer" }}
              >
              </StrippedTable>
            ) : (
              <Loader />
            )}
          </Card>
        </div>

        {this.state.filesStatusFlag && this.state.rowClickCount % 2 == 0 ? (
          <div>

            <div className="row mb-3">
              {/* <div className="col-sm-3 col-md-3">
                <Card title="Instrument Type">
                  {
                    this.state.instrumentsLoaded ? (
                      this.state.instrumentErrMsg ? <span className="alert-danger">{this.state.instrumentErrMsg}</span> :
                        <FormControl className="custom-form-control">
                          <InputLabel id="demo-mutiple-name-label">Instruments</InputLabel>
                          <Select
                            labelId="demo-mutiple-name-label"
                            id="demo-mutiple-name"
                            multiple
                            value={this.state.selectedInstruments}
                            onChange={this.selectInstruments}
                            input={<Input />}
                            MenuProps={MenuProps}
                          >
                            {this.state.instruments.map((item) => (
                              <MenuItem key={item.key} value={item.value}>
                                {item.key}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                    )

                      : <div className="w-100 d-flex">
                        <Loader />
                      </div>
                  }

                </Card>
              </div> */}

              <div className="col-sm-3 col-md-3">
                <Card title="Serial Number Search">
                  <TextField
                    onChange={this.selectSerialNumber}
                    className="w-100"
                    id="standard-basic"
                    label="Serial Number value"
                  />
                </Card>
              </div>
            </div>

            <div className="mb-3">
              <Card title="Job Run">

                {this.state.filesStatusLoaded ? (
                  <div>
                    <StrippedTableFileStatus
                      paginationCallout={this.getRsfeedJobRun}
                      {...this.state.filesStatusTableData}
                      rowClick={this.showModalForZipFileNames}
                      pageCount={this.state.pageCountFileStatus}
                      currentPage={this.state.currentPageFileStatus}
                      paginationLoaded={this.state.paginationLoadedFileStatus}
                    />

                    {this.state.detailedStatusLoaded ? (
                      <ModalDetailedStatus
                        showModal={this.state.showModalForZipFileNames}
                        closeModal={this.showModalForZipFileNames}
                        modalBody={
                          this.state.detailedStatusLoaded ?
                            this.state.detailedStatusData.data[0]['zip_file_name']
                            : ""
                        }
                        modalTitle={"Zip Files for this job run"}
                      ></ModalDetailedStatus>
                    ) : (
                      <div></div>
                    )}
                  </div>
                    
                ) : (
                    <Loader />
                    )
                }
                  </Card>
            </div>
          </div>
        ) : (
          <div />
        )}
        {this.state.zfErrorsFlag ? (
          <div className="mb-3">
            <Card title="Errors">
              <StrippedTable {...this.state.zfErrorsTableData}>
                <ModalDetailedStatus
                  showModal={this.state.showModal}
                  closeModal={this.showModal}
                  modalBody={
                    this.state.modalRowIndex >= 0
                      ? this.state.zfErrorsTableData.data[
                      this.state.modalRowIndex
                      ]["stack_trace_data"]
                      : "Improper Index selected"
                  }
                  modalTitle={"Stack Trace"}
                ></ModalDetailedStatus>
              </StrippedTable>
            </Card>
          </div>
        ) : (
          <div />
        )}
      </>
    );
  }
}

export default VisionRedshiftLoad;
