import { FormControl, Input, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import moment from 'moment';
import react from 'react'
import Card from '../../../components/card/card';
import Datetime from '../../../components/datetime/datetime';
import Loader from '../../../components/loader/loader';
import StrippedTable from '../../../components/table/table';
import { getStatusRedshift } from '../../../services/Vitros/redshift-service';
import get from '../../../utils/http-utils';
import './redshift-contents.scss';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

class RedshiftContents extends react.Component {

    constructor(props) {
        super(props);
        this.state = {
            from: moment(Date.now()).startOf('day').format(),
            to: moment(Date.now()).format(),
            status: [],
            selectedStatus: [],
            zipfilesTableData: { headers: [], data: [] },
            zfStatusTableData: { headers: [], data: [] },
            zfErrorsTableData: { headers: [], data: [] },
            showModal: false,
            modalRowIndex: -1,
            statusLoaded: false,
            statusErrMsg: "",
            zipFilesLoaded: true,
            pageCount: -1,
            currentPage: 1,
            paginationLoaded: false,
            paginationMessage: "",
            serialNumber: "",
            zipfileName: "",
            fileName: ""
        }

        this.setFrom = this.setFrom.bind(this);
        this.setTo = this.setTo.bind(this);
        this.selectStatus = this.selectStatus.bind(this);
        this.getZipfilesStatus = this.getZipfilesStatus.bind(this);
        this.showModal = this.showModal.bind(this);
        this.getZipFiles = this.getZipFiles.bind(this);
        this.setSerialNumber = this.setSerialNumber.bind(this);
        this.setZipfileName = this.setZipfileName.bind(this);
        this.setFileName = this.setFileName.bind(this);
    }

    showModal(ind) {
        this.setState({ showModal: !this.state.showModal, modalRowIndex: ind });
    }

    setTo(to) {
        const endDate = moment(to.target.value).format()
        this.setState({ to: endDate });
        console.log(moment(to.target.value).format());
        this.getZipFiles({ endDate })
        // this.getHealthStats(this.state.from, endDate);
    }

    setFrom(from) {
        const startDate = moment(from.target.value).format();
        this.setState({ from: startDate });
        console.log(moment(from.target.value).format());
        this.getZipFiles({ startDate });

        // this.getHealthStats(startDate, this.state.to);
    }
    setFileName(event) {
        const fileName = event.target.value;
        this.setState({ fileName });
        this.getZipFiles({ fileName });
    }

    getPagination({ startDate = this.state.from, endDate = this.state.to,
        status = this.state.selectedStatus.join(","), serialNumber, zipfileName, fileName,
        pageSize = 20 } = {}) {
        this.setState({ paginationLoaded: false })
        get("/pagination/redshift",
            { "content-type": "application/json" },
            {
                startDate,
                endDate,
                status,
                pageSize,
                serialNumber,
                fileName,
                zipfileName
            }
        ).then(res => {
            this.setState({ pageCount: res.data[0]?.count })
            console.log("count", res.data);
            this.setState({ paginationLoaded: true, paginationMessage: "" })
        }, err => {
            console.log(err)
            this.setState({ paginationLoaded: true, paginationMessage: "Could Load Pagination.(Message:" + err.message + ")" })
        })
    }

    getZipFiles({ startDate = this.state.from, endDate = this.state.to, status = this.state.selectedStatus.join(","), fileName = this.state.fileName, serialNumber = this.state.serialNumber, zipfileName = this.state.zipfileName, page = 1, pageSize = 100 } = {}) {
        this.setState({ zipfilesTableData: { headers: [], data: [] }, zipFilesLoaded: false });
        let params = {
            startDate,
            endDate,
            page,
            pageSize,
            status: status,
            serialNumber,
            zipfileName,
            fileName
        }
        if (page === 1) this.getPagination(params);

        this.setState({ currentPage: page });
        get("/redshift/zipfiles/date",
            { "content-type": "applicaiton/json" }, params
        ).then(resp => {
            console.log(resp);
            if (resp.data.length > 0) {
                const headers = Object.keys(resp.data[0]);
                const data = resp.data;
                const zipFiles = { headers, data };
                this.setState({ zipfilesTableData: zipFiles, zipFilesLoaded: true });
            } else {
                this.setState({ zipfilesTableData: { headers: [], data: [] }, zipFilesLoaded: true });
            }

        }, error => {
            console.log(error);
            this.setState({ zipFilesLoaded: true });
        })
    }

    selectStatus(event) {
        const status = event.target.value;
        this.setState({ selectedStatus: status });
        this.getZipFiles({ status: status.join(",") });
    };

    getStatus() {
        getStatusRedshift().then(({ data, error }) => {
            if (error) {
                this.setState({ statusErrMsg: "Could not load status, please try later.(Message:" + error.message + ")" })
            } else {
                this.setState({ status: data, selectedStatus: [], statusLoaded: true });
                this.getZipFiles({ status: "" });
            }
        })
    }

    setSerialNumber(event) {
        const serialNumber = event.target.value;
        this.setState({ serialNumber });
        this.getZipFiles({ serialNumber });
    };

    setZipfileName(event) {
        const zipfileName = event.target.value;
        this.setState({ zipfileName });
        this.getZipFiles({ zipfileName });
    };

    getZipfilesStatus(data) {
        console.log(data)
        const zfId = data["zip_file_id"];
        const zfName = data["zip_file_name"];
        get("/zipfiles/zipfile/status",
            { "content-type": "applicaiton/json" }, {
            zipFileId: zfId ? zfId : ""
        }
        ).then(resp => {
            console.log("zipfile status --------------------", resp.data)
            if (resp.data.length > 0) {
                const headers = Object.keys(resp.data[0]);
                const data = resp.data;
                const statusTD = { headers, data };
                this.setState({ zfStatusTableData: statusTD });
            }
        })
    }

    componentDidMount() {
        this.getStatus();
    }

    render() {
        return (<>
            <div className="row mb-3">
                <div className="col-sm-6 col-md-6">
                    <Datetime title="Created Date" setTo={this.setTo} setFrom={this.setFrom} />
                </div>
                <div className="col-sm-3 col-md-3">
                    <Card title="Status">
                        {this.state.statusLoaded ?
                            <FormControl className="custom-form-control" >
                                <InputLabel id="demo-mutiple-name-label">Status</InputLabel>
                                <Select
                                    labelId="demo-mutiple-name-label"
                                    id="demo-mutiple-name"
                                    multiple
                                    value={this.state.selectedStatus}
                                    onChange={this.selectStatus}
                                    input={<Input />}
                                    MenuProps={MenuProps}
                                >
                                    {this.state.status.map((item) => (
                                        <MenuItem key={item.key} value={item.value}>
                                            {item.key}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            :
                            <div className="w-100 d-flex">
                                <Loader/>
                            </div>
                        }

                    </Card>
                </div>
                <div className="col-sm-3 col-md-3">
                    <Card title="Serial Number">
                        <TextField onChange={this.setSerialNumber} className="w-100" id="standard-basic" label="Serial Number" />
                    </Card>
                </div>

            </div>
            <div className=" row mb-3">
                <div className="col-sm-3 col-md-3">
                    <Card title="Zipfile Name">
                        <TextField onChange={this.setZipfileName} className="w-100" id="standard-basic" label="Zipfile Name" />
                    </Card>
                </div>
                <div className="col-sm-3 col-md-3">
                    <Card title="Filename">
                        <TextField onChange={this.setFileName} className="w-100" id="standard-basic" label="Filename" />
                    </Card>
                </div>
            </div>

            <div className="mb-3">

                <Card title="Zip file Status">
                    {this.state.zipFilesLoaded ?
                        <StrippedTable
                            paginationCallout={this.getZipFiles}
                            message={this.state.statusLoaded ? "" : "Waiting for status to load."}
                            {...this.state.zipfilesTableData}
                            pageCount={this.state.pageCount}
                            currentPage={this.state.currentPage}
                            paginationLoaded={this.state.paginationLoaded}
                            rowStyles={{ cursor: "pointer" }} /> : <Loader />}

                </Card>

            </div>
        </>)
    }
}

export default RedshiftContents;