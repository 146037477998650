import { FormControl, Input, InputLabel, MenuItem, Select } from '@material-ui/core';
import moment from 'moment';
import react from 'react'
import Card from '../../../components/card/card';
import Datetime from '../../../components/datetime/datetime';
import Loader from '../../../components/loader/loader';
import Modal from '../../../components/modal/modal';
import StrippedTable from '../../../components/table/table';
import { getDatalakeFiles, getDatalakeTypes, getDatalakeFilesPagination, getDatalakeFilesErrors, getDatalakeStats } from '../../../services/Vitros/datalake-service';

import './datalake-contents.scss';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

class DatalakeContents extends react.Component {

    constructor(props) {
        super(props);
        this.state = {
            from: moment(Date.now()).subtract(1, 'days').format(),
            to: moment(Date.now()).format(),
            types: [],
            selectedTypes: [],
            zipfilesTableData: { headers: [], data: [] },
            zfErrorsTableData: { headers: [], data: [] },
            showModal: false,
            showDatalakeModal: false,
            modalRowIndex: -1,
            datalakeModalRowIndex: -1,
            typesLoaded: false,
            typesErrMsg: "",
            zipFilesLoaded: true,
            pageCount: -1,
            currentPage: 1,
            paginationLoaded: false,
            paginationMessage: "",
            statsLoaded: false,
            healthStats: {
                "Job Failed": 0,
                "Job Started": 0,
                "Job Success": 0,
                "No Records": 0
            },
        }

        this.setFrom = this.setFrom.bind(this);
        this.setTo = this.setTo.bind(this);
        this.selectType = this.selectType.bind(this);
        this.getDatalakeFilesErrors = this.getDatalakeFilesErrors.bind(this);
        this.showModal = this.showModal.bind(this);
        this.showDatalakeModal = this.showDatalakeModal.bind(this);
        this.getDatalakeFiles = this.getDatalakeFiles.bind(this);
    }

    showModal(ind) {
        this.setState({ showModal: !this.state.showModal, modalRowIndex: ind });
    }
    showDatalakeModal(ind) {
        this.setState({ showDatalakeModal: !this.state.showDatalakeModal, datalakeModalRowIndex: ind });
    }

    setTo(to) {
        const endDate = moment(to.target.value).format()
        this.setState({ to: endDate });
        console.log(moment(to.target.value).format());
        this.getDatalakeFiles({ endDate })
        // this.getHealthStats(this.state.from, endDate);
    }

    setFrom(from) {
        const startDate = moment(from.target.value).format();
        this.setState({ from: startDate });
        console.log(moment(from.target.value).format());
        this.getDatalakeFiles({ startDate });
        // this.getHealthStats(startDate, this.state.to);
    }

    getDatalakeStats({ startDate = this.state.from, endDate = this.state.to, jobType = this.state.selectedTypes.join(",") }) {
        //get health stats call out /getHealth
        this.setState({ statsLoaded: false })

        getDatalakeStats({ startDate, endDate, jobType }).then(resp => {
            if (resp.hasOwnProperty("error")) {
                this.setState({ statsLoaded: true })
            } else {
                this.setState({ healthStats: resp.data.healthStats, statsLoaded: true })
            }
        })
    }

    getPagination({ startDate = this.state.from, endDate = this.state.to,
        jobType = this.state.selectedTypes.join(","),
        pageSize = 100 } = {}) {
        this.setState({ paginationLoaded: false })
        getDatalakeFilesPagination(
            {
                startDate,
                endDate,
                jobType,
                pageSize
            }
        ).then(res => {
            if (res.data instanceof Array) {
                this.setState({ pageCount: res.data[0]?.count, paginationLoaded: true, paginationMessage: "" })
                console.log("count", res.data);
            } else {
                this.setState({ pageCount: 0, paginationLoaded: true, paginationMessage: "" })
            }

        }, err => {
            console.log(err)
            this.setState({ paginationLoaded: true, paginationMessage: "Could Load Pagination.(Message:" + err.message + ")" })
        })
    }

    getDatalakeFiles({ startDate = this.state.from, endDate = this.state.to, types = this.state.selectedTypes.join(","), page = 1, pageSize = 100 } = {}) {
        this.setState({ zipfilesTableData: { headers: [], data: [] }, zipFilesLoaded: false, statsLoaded: false });
        this.getDatalakeStats({ startDate, endDate, jobType: types })
        let params = {
            startDate,
            endDate,
            page,
            pageSize,
            jobType: types,
        }
        if (page === 1) this.getPagination(params);

        this.setState({ currentPage: page });
        getDatalakeFiles(params).then(resp => {
            console.log(resp);
            if (resp.data && resp.data.length > 0) {
                const headers = Object.keys(resp.data[0]).filter(val => val !== "job_run_id");
                // const data = resp.data;
                const data = resp.data.map((val, ind) => {
                    val["error_data"] = val["error"];
                    if (val["error_data"]) {
                        val["error"] = (
                            <span className="icon" onClick={() => this.showDatalakeModal(ind)} title="Info">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-info" viewBox="0 0 16 16">
                                    <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                                </svg>
                            </span>
                        )
                    }

                    val["job_run_id"] = (
                        <span className="d-inline-block text-truncate" style={{ maxWidth: "100px" }} title={val["job_run_id"]}>
                            {val["job_run_id"]}
                        </span>
                    )
                    return val;
                });
                const zipFiles = { headers, data };
                this.setState({ zipfilesTableData: zipFiles, zipFilesLoaded: true });
            } else {
                this.setState({ zipfilesTableData: { headers: [], data: [] }, zipFilesLoaded: true });
            }

        }, error => {
            console.log(error);
            this.setState({ zipFilesLoaded: true });
        })
    }

    selectType(event) {
        const status = event.target.value;
        this.setState({ selectedTypes: status });
        this.getDatalakeFiles({ types: status.join(",") });
    };

    getDatalakeTypes() {
        getDatalakeTypes().then(({ data, error }) => {
            if (error) {
                this.setState({ typesErrMsg: "Could not load status, please try later.(Message:" + error.message + ")", typesLoaded: true })
            } else {
                this.setState({ types: data, selectedTypes: [], typesLoaded: true });
                this.getDatalakeFiles({ types: "" });
            }
        })
    }

    getDatalakeFilesErrors(data) {
        console.log(data)
        const jobType = data["type"];
        const Id = data["id"];
        getDatalakeFilesErrors({
            jobType,
            Id,
        }).then(resp => {
            console.log("zipfile status --------------------", resp.data)
            if (resp.data.length > 0) {
                const headers = Object.keys(resp.data[0]);

                const data = resp.data.map((val, ind) => {
                    val["error_data"] = val["error"];
                    val["error"] = (
                        <span className="icon" onClick={() => this.showModal(ind)} title="Info">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-info" viewBox="0 0 16 16">
                                <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                            </svg>
                        </span>
                    )
                    return val;
                });
                const errors = { headers, data };
                this.setState({ zfErrorsTableData: errors });
            } else {
                this.setState({ zfErrorsTableData: { headers: [], data: [] } });
            }
        })
    }

    componentDidMount() {
        this.getDatalakeTypes();
    }

    render() {
        return (<>
            <div className="row mb-3">
                <div className="col-sm-6 col-md-6">
                    <Datetime title="Created Date" to={moment(Date.now()).format('YYYY-MM-DDTHH:mm')} from={moment(Date.now()).subtract(1, 'days').format('YYYY-MM-DDTHH:mm')} setTo={this.setTo} setFrom={this.setFrom} />
                </div>
                <div className="col-sm-3 col-md-3">
                    <Card title="Job Types">
                        {this.state.typesLoaded ?
                            <FormControl className="custom-form-control" >
                                <InputLabel id="demo-mutiple-name-label">Types</InputLabel>
                                <Select
                                    labelId="demo-mutiple-name-label"
                                    id="demo-mutiple-name"
                                    multiple
                                    value={this.state.selectedTypes}
                                    onChange={this.selectType}
                                    input={<Input />}
                                    MenuProps={MenuProps}
                                >
                                    {this.state.types.map((item) => (
                                        <MenuItem key={item.key} value={item.value}>
                                            {item.key}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            :
                            <div className="w-100 d-flex">
                                <Loader />
                            </div>
                        }

                    </Card>
                </div>
            </div>
            <div className="row mb-3">
                {Object.keys(this.state.healthStats).map((val, index) => {
                    return (<div className="col-sm-3 col-md-3 mb-3" key={val + index}>
                        <Card title={val}>
                            <div>
                                {this.state.statsLoaded ? <h1 className="text-center display-4 bold">{this.state.healthStats[val]}</h1> : <Loader />}
                            </div>
                        </Card>
                    </div>)
                })}

            </div>

            <div className="mb-3">

                <Card title="Zip file Status">
                    {this.state.zipFilesLoaded ?
                        <StrippedTable
                            paginationCallout={this.getDatalakeFiles}
                            message={this.state.typesLoaded ? "" : "Waiting for types to load."}
                            {...this.state.zipfilesTableData}
                            rowClick={this.getDatalakeFilesErrors}
                            pageCount={this.state.pageCount}
                            currentPage={this.state.currentPage}
                            paginationLoaded={this.state.paginationLoaded}
                            rowStyles={{ cursor: "pointer" }} >
                            <Modal showModal={this.state.showDatalakeModal} closeModal={this.showDatalakeModal}
                                modalBody={this.state.datalakeModalRowIndex >= 0 ? this.state.zipfilesTableData.data[this.state.datalakeModalRowIndex]["error_data"] : "Improper Index selected"}
                                modalTitle={"Stack Trace"}
                            ></Modal>
                        </StrippedTable> : <Loader />}

                </Card>

            </div>

            <div className="mb-3">

                <Card title="Errors">
                    <StrippedTable {...this.state.zfErrorsTableData} >
                        <Modal showModal={this.state.showModal} closeModal={this.showModal}
                            modalBody={this.state.modalRowIndex >= 0 ? this.state.zfErrorsTableData.data[this.state.modalRowIndex]["error_data"] : "Improper Index selected"}
                            modalTitle={"Stack Trace"}
                        ></Modal>
                    </StrippedTable>
                </Card>

            </div>
        </>)
    }
}

export default DatalakeContents;