import './db-file-management.scss';
import Card from '../../../components/card/card';
import { timeFrame, weekDays, oneHourBlock } from './db-file-management-constants'
import { getJNumberInstrumentID, getFileData, getInstrumentType } from '../../../services/Vitros/db-file-management-service';
import { useState, useEffect } from 'react';
import moment from 'moment';

import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import { FormControl, InputLabel, LinearProgress, MenuItem, Select } from '@mui/material';

const DBFileManagement = () => {

    let firstDay = new Date();
    firstDay = moment(firstDay).subtract(6, 'day');
    const [dateValue, setDateValue] = useState(firstDay);
    const [jnumberMap, setJnumberMap] = useState([]);
    const [instrumentTypes, setInstrumentTypes] = useState([]);
    const [instrumentTypeSelected, setInstrumentTypeSelected] = useState('');
    const [instrumentIdSelected, setInstrumentId] = useState([]);
    const [rawDBFileData, setRawDBFileData] = useState([]);
    const [isDataLoading, setIsDataLoading] = useState(true);

    const [dateArray, setDateArray] = useState([
        firstDay,
        moment(firstDay).add(1, 'days'),
        moment(firstDay).add(2, 'days'),
        moment(firstDay).add(3, 'days'),
        moment(firstDay).add(4, 'days'),
        moment(firstDay).add(5, 'days'),
        moment(firstDay).add(6, 'days')
    ]);

    useEffect(() => {
        setIsDataLoading(true);
        getInstrumentType().then(({ error, data }) => {
            if (!error) {
                setInstrumentTypes(data);
                setIsDataLoading(false);
            }
        });
    }, []);

    useEffect(() => {
        setJnumberMap([]);
        setIsDataLoading(true);
        getJNumberInstrumentID(instrumentTypeSelected).then(({ error, data }) => {
            if (!error) {
                setJnumberMap(data);
                setIsDataLoading(false);
            }
        });
    }, [instrumentTypeSelected]);

    useEffect(() => {
        if (instrumentIdSelected.length > 0) {
            loadFileData();
        }
    }, [dateArray]);

    const dateArraySet = (dateNewValue) => {
        setDateValue(dateNewValue);
        setDateArray([
            moment(dateNewValue),
            moment(dateNewValue).add(1, 'days'),
            moment(dateNewValue).add(2, 'days'),
            moment(dateNewValue).add(3, 'days'),
            moment(dateNewValue).add(4, 'days'),
            moment(dateNewValue).add(5, 'days'),
            moment(dateNewValue).add(6, 'days')
        ]);
    }

    const getFilesForDay = (currentDate, fileType) => {
        
        const currentDayStart =  moment(currentDate._d).startOf('day').format('YYYY-MM-DD HH:mm:ss');
        const currentDayEnd =  moment(currentDate._d).endOf('day').format('YYYY-MM-DD HH:mm:ss');

        //filter data for a particular day
        const sortedDayFileData = rawDBFileData.filter(d => {
            if (d.file_nm.includes(fileType) && currentDayStart === d.date_id) {
                return d;
            }
        });

        // sort data as per date
        sortedDayFileData.sort((a,b) =>{
            const dateA = new Date(a.max_event_time);
            const dateB = new Date(b.max_event_time);
            return dateA - dateB;
        });

        let previousDivEndTime = currentDayStart;
        let blockJSXDate = [];

        sortedDayFileData.map((d) => {
            const minEventTime = moment(d.min_event_time);
            const maxEventTime = moment(d.max_event_time);

            const isMinEventTimeSameDay = minEventTime.isSame(currentDayStart, 'day');
            const isMaxEventTimeSameDay = maxEventTime.isSame(currentDayStart, 'day');

            let statusClass = '';
            
            switch(d.status) {
                case 'LOADC' : {
                    // load complete - green
                    statusClass = "status-complete white-border";
                    break;
                }
                case 'LOADS' : {
                    // load start - yellow
                    statusClass = "status-start white-border";
                    break;
                }
                case 'LOADE' : {
                    // load error - red
                    statusClass = "status-error white-border";
                    break;
                }
                default: {
                    break;
                }
            }

            const emptyTimeDiff = moment(minEventTime).diff(previousDivEndTime, 'minutes');
            const emptyDivHeight = (oneHourBlock * emptyTimeDiff / 60) + "vh" ;

            if (emptyTimeDiff > 2) {
                blockJSXDate.push(<div style={{ height: emptyDivHeight }}></div>);
            }

            if (!isMinEventTimeSameDay && isMaxEventTimeSameDay) {
                // start time falls on previous day and end time falls on same day
                
                const timeDiffFromMidnight = moment(maxEventTime).diff(currentDayStart, 'minutes');
                const blockHeight = oneHourBlock * timeDiffFromMidnight / 60 ;
                const heightProperty = blockHeight + "vh";
                blockJSXDate.push(<Tooltip title={d.file_nm}><div className={statusClass} style={{ height: heightProperty }}></div></Tooltip>);

            
            } else if (isMinEventTimeSameDay && isMaxEventTimeSameDay) {
                // start time and end time falls on same day

                const timeDiff = maxEventTime.diff(minEventTime, 'minutes');
                const blockHeight = oneHourBlock * timeDiff / 60 ;
                const heightProperty = blockHeight + "vh";
                blockJSXDate.push(<Tooltip title={d.file_nm}><div className={statusClass} style={{ height: heightProperty }}></div></Tooltip>);
                
            } else if (isMinEventTimeSameDay && !isMaxEventTimeSameDay) {
                // start time falls on same day and end time falls on next day

                const timeTillMidnight = moment(currentDayEnd).diff(minEventTime, 'minutes');
                const blockHeight = oneHourBlock * timeTillMidnight / 60 ;
                const heightProperty = blockHeight + "vh";
                blockJSXDate.push(<Tooltip title={d.file_nm}><div className={statusClass} style={{ height: heightProperty }}></div></Tooltip>);


            } else if (!isMinEventTimeSameDay && !isMaxEventTimeSameDay) {
                // start time falls on previous day and end time falls on next day - covers full day
               
                const blockHeight = oneHourBlock * 24  ;
                const heightProperty = blockHeight + "vh";
                blockJSXDate.push(<Tooltip title={d.file_nm}><div className={statusClass} style={{ height: heightProperty }}></div></Tooltip>);
            }
            previousDivEndTime = maxEventTime.format('YYYY-MM-DD HH:mm:ss');
           
        });
        return (blockJSXDate);
    }

    const loadFileData = () => {
        setIsDataLoading(true);
        const startDate = moment(dateArray[0]._d).startOf('day').format();
        const endDate = moment(dateArray[6]._d).endOf('day').format();
        getFileData(startDate, endDate, instrumentIdSelected).then(({ error, data }) => {
            if (!error) {
                //console.log("data", data);
                setRawDBFileData(data);
                setIsDataLoading(false);
            }
        });
    }

    const loadWeek = (weekframe) => {
        let d =  new Date(dateValue);
        weekframe === "prev" ? d.setDate(d.getDate() - 7) : d.setDate(d.getDate() + 7);
        dateArraySet(d);
    }

    return (
        <div>

            {/*Instrument data filters and buttons*/}
            <div className="row mb-3">
                <Card title="Instrument Data Filter">
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label="Start Date"
                                    value={dateValue}
                                    onChange={(newValue) => {
                                        dateArraySet(newValue)
                                    }}
                                    renderInput={(params) => <TextField sx={{ m: 1 }} {...params} />}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl sx={{ m: 1, width: 0.8 }}>
                                <InputLabel id="instrument-type-label">Instrument Type</InputLabel>
                                <Select
                                    labelId="instrument-type-label"
                                    label="Instrument Type"
                                    value={instrumentTypeSelected}
                                    onChange={(newValue) => {
                                        setInstrumentTypeSelected(newValue.target.value);
                                    }}
                                >
                                    {instrumentTypes && instrumentTypes.length > 0 && instrumentTypes.map(
                                        (instValue, index) => {
                                            return (
                                                <MenuItem value={instValue.id} key={index} id={index}>{instValue.desc}</MenuItem>
                                            )
                                        }
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={jnumberMap}
                                getOptionLabel={option => option.jno + ''}
                                renderOption={(props, option) => {
                                    return (
                                        <li {...props} key={option.id} value={option.id}>
                                            {option.jno}
                                        </li>
                                    );
                                }}
                                sx={{ m: 1 }}
                                onChange={(newValue) => {
                                    setInstrumentId(newValue.target.attributes.value.nodeValue);
                                }}
                                renderInput={(params) => <TextField {...params} label="JNumber" />}
                            />
                        </Grid>
                        <Grid item xs={1.5}>
                            <Button sx={{ m: 1 }} className="buttonLoadData" variant="contained"
                                onClick={() => loadFileData()}
                            >Load Data</Button>
                        </Grid>
                        <Grid item xs={1.5}>
                            <div className='status-complete legend'>Complete</div>
                            <div className='status-start legend'>Load in Progress</div>
                            <div className='status-error legend'>Error</div>
                        </Grid>
                    </Grid>
                </Card>
            </div>
                            
            {isDataLoading ? <LinearProgress /> : <></>}

            {/*Instrument data UI */}
            <div className="row">
                <Card>
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container rowSpacing={0} columnSpacing={0}>
                            
                            {/* Column for left side hour time*/ }
                            <Grid item xs={1}>
                                <Grid container direction="column" rowSpacing={0} columnSpacing={0}>
                                    <div className='weekDayDate'>
                                        <Button onClick={() => loadWeek("prev")}> &lt; Prev</Button>
                                    </div>
                                    <div className='ABColBuffer'></div>
                                    {timeFrame && timeFrame.map((hour, index) => {
                                        return (<Grid item key={index}>
                                            <div className="colHour" key={index}>{hour}</div>
                                        </Grid>)
                                    })
                                    }
                                </Grid>
                            </Grid>
                            
                            {/* main column containing db file management data*/ }
                            <Grid item xs={10}>
                                <Grid container rowSpacing={0} columnSpacing={0}>

                                    {dateArray && dateArray.length > 0 && dateArray.map(
                                        (currentLoopDate, index) => {
                                            return (
                                                <Grid item xs={1.714} key={index}>
                                                    <div className="dayOfWeek">
                                                        <Grid container direction="column" rowSpacing={0} columnSpacing={0}>
                                                            {/* Column header containing day and date value */}
                                                            <div className="weekDayDate">{weekDays[currentLoopDate.day()]} {currentLoopDate.format('MM/DD/YYYY')}</div>
                                                            <div>
                                                                <Grid container rowSpacing={0} columnSpacing={0}>

                                                                    {/* Day column "A" for db file data */}
                                                                    <Grid item xs={6}>
                                                                        <div className="ABColBuffer">A</div>
                                                                        <div className="dataFileData">
                                                                            {getFilesForDay(currentLoopDate, "A.zip")}
                                                                        </div>
                                                                    </Grid>

                                                                    {/* Day column "B" for db file data */}
                                                                    <Grid item xs={6}>
                                                                        <div className="ABColBuffer">B</div>
                                                                        <div className="dataFileData">
                                                                            {getFilesForDay(currentLoopDate, "B.zip")}
                                                                        </div>
                                                                    </Grid>
                                                                </Grid>
                                                            </div>
                                                        </Grid>
                                                    </div>
                                                </Grid>
                                            );
                                        }
                                    )}

                                </Grid>
                            </Grid>
                            
                            {/*column for right side hour time*/} 
                            <Grid item xs={1}>
                                <Grid container direction="column" rowSpacing={0} columnSpacing={0}>
                                    <div className='weekDayDate'>
                                        <Button onClick={() => loadWeek("next")}>Next &gt;</Button>
                                    </div>
                                    <div className='ABColBuffer'></div>
                                    {timeFrame && timeFrame.map((hour, index) => {
                                        return (<Grid item key={index}><div className="colHour" key={index}>{hour}</div></Grid>)
                                    })
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Card>
            </div>
        </div>
    );
}

export default DBFileManagement;