import react from 'react'
import Card from '../../../components/card/card';
import './stats.scss'

import { FormControl, Input, InputLabel, MenuItem, Select } from '@material-ui/core';
import * as moment from 'moment';
import Datetime from '../../../components/datetime/datetime';
import BasicColumnChart from '../../../components/basic-column-chart/basic-column-chart';
import BasicLineChart from '../../../components/basic-line-chart/basic-line-chart';
import Loader from '../../../components/loader/loader';
import NoDataCard from '../../../components/card/no-data/no-data-card';
import { getChartDataByDay, getChartDataByErrorName, getChartDataByInterumentType, getHealthStats, lookupInstrumentType, getChartDataByAnalyzersType, getChartDataByDeviceSize, getChartDataByloadTime } from './../../../services/Vitros/stats-service'


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

class Stats extends react.Component {

    constructor(props) {
        super(props)

        this.state = {
            healthStats: {
                "Completed": 0,
                "Unzipped": 0,
                "Error": 0,
                "Total": 0
            },
            statsLoaded: false,
            from: moment(Date.now()).startOf('day').format(),
            to: moment(Date.now()).format(),
            instruments: [],
            selectedInstruments: [],
            instrumentCategories: [],
            instrumentSeries: [],
            analyzerCategories: [],
            analyzerSeries: [],
            sizeCategories: [],
            sizeSeries: [],
            loadTimeCategories: [],
            loadTimeSeries: [],
            errorByNameCategories: [],
            errorByNameSeries: [],
            errorByDayCategories: [],
            errorByDaySeries: [],
            instrumentsLoaded: false,
            instrumentErrMsg: "",
            analyzerAPIDataLoaded: false,
            loadTimeAPIDataLoaded: false,
            sizeAPIDataLoaded: false,
            instrumentAPIDataLoaded: false
        }

        this.selectInstruments = this.selectInstruments.bind(this);
        this.setFrom = this.setFrom.bind(this);
        this.setTo = this.setTo.bind(this);
    }

    setTo(to) {
        const endDate = moment(to.target.value).format()
        this.setState({ to: endDate });
        console.log(moment(to.target.value).format())
        this.getHealthStats({ endDate });
    }

    setFrom(from) {
        const startDate = moment(from.target.value).format();
        this.setState({ from: startDate });
        console.log(moment(from.target.value).format())
        this.getHealthStats({ startDate });
    }

    getInstruments() {
        this.setState({ instrumentsLoaded: false });
        lookupInstrumentType().then(response => {
            if (response.hasOwnProperty('error')) {
                console.log("error reponse instrument lookup", response);
                this.setState({ instrumentsLoaded: true, instrumentErrMsg: "Could not load instruments, please try later.(Message:" + response.error + ")" });
            } else {
                console.log("success reponse instrument lookup", response);
                this.setState({ instrumentsLoaded: true, instruments: response.data });
            }
        })

    }

    getHealthStats({ startDate = this.state.from, endDate = this.state.to, instrumentType = this.state.selectedInstruments.join(",") } = {}) {
        //get health stats call out /getHealth
        this.setState({ statsLoaded: false })

        getHealthStats(startDate, endDate, instrumentType).then(resp => {
            if (resp.hasOwnProperty("error")) {
                this.setState({ statsLoaded: true })
            } else {
                this.setState({ healthStats: resp.data.healthStats, statsLoaded: true })
            }
        })
    }
    selectInstruments(event) {
        // console.log("selected instruments", event.target.value)
        this.setState({ selectedInstruments: event.target.value });
        this.getHealthStats({ instrumentType: event.target.value.join(",") })
    };

    getChartDataByInterumentType() {
        getChartDataByInterumentType().then(({ error, data }) => {
            if (!error) {
                this.setState({ instrumentCategories: data.categories, instrumentSeries: data.series });
                this.setState({ instrumentAPIDataLoaded: true });
            }
        });
    }
    getChartDataByAnalyzersType() {
        getChartDataByAnalyzersType().then(({ error, data }) => {
            if (!error) {
                this.setState({ analyzerCategories: data.categories, analyzerSeries: data.series });
                this.setState({ analyzerAPIDataLoaded: true });
            }
        });
    }

    getChartDataByDeviceSize() {
        getChartDataByDeviceSize().then(({ error, data }) => {
            if (!error) {
                this.setState({ sizeCategories: data.categories, sizeSeries: data.series });
                this.setState({ sizeAPIDataLoaded: true });
            }
        });
    }
    
    getChartDataByloadTime() {
        getChartDataByloadTime().then(({ error, data }) => {
            if (!error) {
                this.setState({ loadTimeCategories: data.categories, loadTimeSeries: data.series });
                this.setState({ loadTimeAPIDataLoaded: true });
            }
        });
    }
    getChartDataByErrorName() {
        getChartDataByErrorName().then(({ error, data }) => {
            if (!error) {
                this.setState({ errorByNameCategories: data.categories, errorByNameSeries: data.series })
            }
        });
    }

    getChartDataByDay() {
        getChartDataByDay().then(({ error, data }) => {
            if (!error) {
                this.setState({ errorByDayCategories: data.categories, errorByDaySeries: data.series })
            }
        });
    }

    componentDidMount() {
        //call for data
        this.getHealthStats();
        this.getInstruments();
        this.getChartDataByInterumentType();
        this.getChartDataByAnalyzersType();
        this.getChartDataByDeviceSize();
        this.getChartDataByloadTime();

        fetch('/')
        
    }

    render() {
        return (<>
            <div className="row mb-3">
                <div className="col-sm-6 col-md-6">
                    <Datetime title="Created Date" setTo={this.setTo} setFrom={this.setFrom} />
                </div>
                <div className="col-sm-3 col-md-3">
                    <Card title="Instrument Type">
                        {
                            this.state.instrumentsLoaded ? (
                                this.state.instrumentErrMsg ? <span className="alert-danger">{this.state.instrumentErrMsg}</span> :
                                    <FormControl className="custom-form-control" >
                                        <InputLabel id="demo-mutiple-name-label">Instruments</InputLabel>
                                        <Select
                                            labelId="demo-mutiple-name-label"
                                            id="demo-mutiple-name"
                                            multiple
                                            value={this.state.selectedInstruments}
                                            onChange={this.selectInstruments}
                                            input={<Input />}
                                            MenuProps={MenuProps}
                                        >
                                            {this.state.instruments.map((item) => (
                                                <MenuItem key={item.key} value={item.value}>
                                                    {item.key}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                            )

                                : <div className="w-100 d-flex">
                                    <Loader />
                                </div>
                        }
                    </Card>
                </div>
            </div>
            <div className="row mb-3">
                {Object.keys(this.state.healthStats).map((val, index) => {
                    if (val === "Unzipped") return "";

                    return (<div className="col-sm-3 col-md-3" key={val + index}>
                        <Card title={val}>
                            <div>
                                {this.state.statsLoaded ? <h1 className="text-center display-4 bold">{this.state.healthStats[val]}</h1> : <Loader />}
                            </div>
                        </Card>
                    </div>)
                })}

            </div>
            {/* <div className="card shadow-sm mb-3 d-flex">
                {this.state.instrumentCategories.length > 0 && this.state.instrumentSeries.length > 0 ?
                    <BasicColumnChart title="# of Zip file by Instrument Type"
                        categories={this.state.instrumentCategories}
                        series={this.state.instrumentSeries}
                        xAxisTitle="Created Date"
                        yAxisTitle="Number of Zip Files"
                        legend={true}
                    /> : <Loader />}
            </div> */}
            <div className="card shadow-sm mb-3 d-flex">
                {this.state.analyzerCategories.length > 0 && this.state.analyzerSeries.length > 0 ?
                    <BasicLineChart title="Analyzers per day per device trend"
                        categories={this.state.analyzerCategories}
                        series={this.state.analyzerSeries}
                        xAxisTitle="Created Date"
                        yAxisTitle="Count"
                        legend={true}
                    /> : (this.state.analyzerAPIDataLoaded && (this.state.analyzerCategories.length === 0 || this.state.analyzerSeries.length === 0) ?
                        <NoDataCard title="Analyzers per day per device trend"/>
                        : <Loader />)
                }
            </div>
            <div className="card shadow-sm mb-3 d-flex">
                {this.state.instrumentCategories.length > 0 && this.state.instrumentSeries.length > 0 ?
                    <BasicLineChart title="Zip files per day per device trend"
                        categories={this.state.instrumentCategories}
                        series={this.state.instrumentSeries}
                        xAxisTitle="Created Date"
                        yAxisTitle="Count"
                        legend={true}
                    /> : (this.state.instrumentAPIDataLoaded && (this.state.instrumentCategories.length === 0 || this.state.instrumentSeries.length === 0) ?
                        <NoDataCard title="Zip files per day per device trend"/>
                        : <Loader />)
                }
            </div>
            <div className="card shadow-sm mb-3 d-flex">
                {this.state.sizeCategories.length > 0 && this.state.sizeSeries.length > 0 ?
                    <BasicLineChart title="Size per day per device trend"
                        categories={this.state.sizeCategories}
                        series={this.state.sizeSeries}
                        xAxisTitle="Created Date"
                        yAxisTitle="Size (MB)"
                        legend={true}
                    /> : (this.state.sizeAPIDataLoaded && (this.state.sizeCategories.length === 0 || this.state.sizeSeries.length === 0) ?
                        <NoDataCard title="Size per day per device trend"/>
                        : <Loader />)
                }
            </div>
            <div className="card shadow-sm mb-3 d-flex">
                {this.state.loadTimeCategories.length > 0 && this.state.loadTimeSeries.length > 0 ?
                    <BasicLineChart title="Average File Load Time"
                        categories={this.state.loadTimeCategories}
                        series={this.state.loadTimeSeries}
                        xAxisTitle="Created Date"
                        yAxisTitle="Seconds"
                        legend={true}
                    /> : (this.state.loadTimeAPIDataLoaded && (this.state.loadTimeCategories.length === 0 || this.state.loadTimeSeries.length === 0) ?
                        <NoDataCard title="Average File Load Time"/>
                        : <Loader />)
                }
            </div>  
            
        </>)
    }
}

export default Stats;