import get from '../../utils/http-utils'

export async function getRedshiftLoadTypes() {
    let headers = {
        "content-type": "application/json"
    }

    return get("/lookup/type-datalake", headers)
        .then(resp => {
            const types = Object.values(resp.data).reduce((acc, val, ind) => {
                let tempObj = { key: val, value: val }
                acc.push(tempObj)
                return acc;
            }, [])
            return { data: types }

        }, err => {
            return { error: err.message };
        })
}


export async function getRedshiftLoadStatus(params) {
    return get("/Vision/rsfeed/lookupStatus")
        .then(resp => {
            console.log("status lookup resp", resp);
            const data = Object.values(resp.data).reduce((acc, val, ind) => {
                let tempObj = { key: val, value: val }
                acc.push(tempObj)
                return acc;
            }, [])
            return { data: data };
        }, err => {
            console.log("status lookup resp err", err.message)
            return { error: err.message }
        });
}

export function getInstrumentType() {
    return get("/Vision/lookup/instrumenttype")
        .then(resp => {
            console.log("instruments resp", resp);
            const data = Object.values(resp.data).reduce((acc, val, ind) => {
                let tempObj = { key: val, value: val }
                acc.push(tempObj)
                return acc;
            }, [])
            return { data: data };
        }, err => {
            console.log("instruments resp err", err.message)
            return { error: err.message }
        });
}


export async function getRedshiftLoadStatusErrors(params) {
    let headers = {
        "content-type": "application/json"
    }

    return get("/datalake/errors", headers, params)
        .then(resp => {
            return resp;
        }, err => {
            return { error: err.message };
        })
}

export async function getRedshiftLoadStatusPagination(params) {
    let headers = {
        "content-type": "application/json"
    }
   
    return get("/pagination/datalake", headers, params)
        .then(resp => {
            return resp;
        }, err => {
            return { count: 0 };
        })
}

export function getRedshiftLoadStats(params) {
    //get health stats call out /getHealth
    return get("/redshiftload/stats", { "content-type": "application/json" }, params).then(resp => {
        // console.log("response", resp);
        if (resp.data?.length > 0 && Object.keys(resp.data[0]).length > 0) {
            const healthStats = resp.data.reduce((acc, val) => {
                acc["Job Failed"] = val["Job Failed"] ? acc["Job Failed"] += val["Job Failed"] : acc["Job Failed"];
                acc["Job Started"] = val["Job Started"] ? acc["Job Started"] += val["Job Started"] : acc["Job Started"];
                acc["Job Success"] = val["Job Success"] ? acc["Job Success"] += val["Job Success"] : acc["Job Success"];
                acc["No Records"] = val["No Records"] ? acc["No Records"] += val["No Records"] : acc["No Records"];
                acc["Job Inactive"] = val["Job Inactive"] ? acc["Job Inactive"] += val["Job Inactive"] : acc["Job Inactive"];
                return acc;
            }, { "Job Failed": 0, "Job Started": 0, "Job Success": 0, "No Records": 0, "Job Inactive": 0 })
            return { data: { healthStats } }
        } else return { data: { healthStats: { "Job Failed": 0, "Job Started": 0, "Job Success": 0, "No Records": 0 } } }
    }, (err) => {
        // console.log("error", err);
        return { error: err.message }
    })

}