import react from 'react';
import { ReactKeycloakProvider  } from '@react-keycloak/web'
import keycloak from './utils/keycloak'
import RouterInfo from './utils/router'
import { KEY_CLOAK_LOGOUT_URL } from './utils/constants';
import { checkAppAccessibility } from './utils/accessibility-check';
import PermissionError from './components/error/permission-error';

const onload = {     
  onLoad: 'login-required',
  silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
  pkceMethod: 'S256',
}

const logoutOptions = {
  redirectUri : KEY_CLOAK_LOGOUT_URL
}

class KApp extends react.Component {

  constructor(props) {
    super(props);
    this.state = {
      keycloakToken: '',
      authCompleted: false,
      user: null
    }
  }

  canUserAccess () {
    return checkAppAccessibility(this.state.user.UserType);
  }

  signoutUser () {
    keycloak.logout(logoutOptions).then((success) => {
      console.log("--> log: logout success ", success );
    }).catch((error) => {
        console.log("--> log: logout error ", error );
    });
  }

  render() {
      return (
        <ReactKeycloakProvider 
          authClient={keycloak} 
          initOptions={onload}
          onTokens={({ token }) => {
            localStorage.setItem("keycloakToken", token);
            console.log(token)
            keycloak.loadUserInfo().then(userInfo => {
              this.setState({ user: userInfo});
              this.setState({authCompleted: true});
            });
          }}
          onTokenExpired= {() => keycloak.updateToken(60000000)}    
        >

          { this.state.authCompleted 
            ? ( this.state.user && this.canUserAccess() ? <RouterInfo signoutUser={this.signoutUser}/> :  <PermissionError/>)
            : null
          }
          
        </ReactKeycloakProvider>
       );
  }
}

export default KApp;
