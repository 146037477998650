import Keycloak from 'keycloak-js'
import { KEY_CLOAK_URL } from './constants'
 
// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'

const keycloak = new Keycloak({
        "realm": "keycloak-on-aws",
        "url": KEY_CLOAK_URL,
        "clientId": "vue",
      })

export default keycloak
