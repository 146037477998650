import { TextField } from '@material-ui/core';
import moment from 'moment';
import react from 'react';
import Card from '../card/card';

class Datetime extends react.Component {

    constructor(props) {
        super(props)
        this.state = {
            defaultTo: props.to ? props.to : moment(Date.now()).format('YYYY-MM-DDTHH:mm'),
            defaultFrom: props.from ? props.from : moment(Date.now()).format('YYYY-MM-DDT00:00')
        }
    }


    render() {

        return (
            <Card title={this.props.title}>
                <TextField
                    id="datetime-local"
                    label="From"
                    type="datetime-local"
                    defaultValue={this.state.defaultFrom}
                    className="col-sm-6 pe-4"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={this.props.setFrom}
                />
                <TextField
                    id="datetime-local"
                    label="To"
                    type="datetime-local"
                    defaultValue={this.state.defaultTo}
                    className="col-sm-6 pe-4"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={this.props.setTo}
                />
            </Card>
        )
    }
}

export default Datetime
