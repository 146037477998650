import get from '../../utils/http-utils'

export async function getStatusRedshift() {
    let headers = {
        "content-type": "application/json"
    }

    return get("/lookup/status-redshift", headers)
        .then(resp => {
            const status = Object.values(resp.data).reduce((acc, val, ind) => {
                let tempObj = { key: val, value: val }
                acc.push(tempObj)
                return acc;
            }, [])
            return { data: status }

        }, err => {
            return { error: err.message };
        })
}