import react from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official';
require("highcharts/modules/exporting")(Highcharts);

class BasicColumnChart extends react.Component {

    constructor(props) {
        super(props);
        this.state = {
            options: {
                chart: {
                    type: 'column'
                },
                title: {
                    text: this.props.title ? this.props.title : "title goes here"
                },
                subtitle: {
                    text: ''
                },
                legend: this.props.legend ? {
                    align: 'right',
                    x: 0,
                    verticalAlign: 'top',
                    y: 25,
                    floating: false,
                    backgroundColor:
                        Highcharts.defaultOptions.legend.backgroundColor || 'white',
                    borderColor: '#CCC',
                    borderWidth: 1,
                    shadow: true,
                    layout: "vertical"
                } : {},
                xAxis: {
                    categories: this.props.categories,
                    crosshair: true,
                    title: {
                        text: this.props.xAxisTitle
                    },

                },
                yAxis: {
                    min: 0,
                    title: {
                        text: this.props.yAxisTitle
                    }
                },
                tooltip: {
                    headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                    pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                        '<td style="padding:0"><b>{point.y}</b></td></tr>',
                    footerFormat: '</table>',
                    shared: true,
                    followPointer: true,
                    useHTML: true
                },
                plotOptions: {
                    column: {
                        pointPadding: 0.1,
                        borderWidth: 0
                    },
                },
                series: this.props.series
            }
        }
    }


    render() {
        return (
            <HighchartsReact
                highcharts={Highcharts}
                options={this.state.options}
            />
        )
    }

}


export default BasicColumnChart;