import react, { useState, useEffect } from "react";
import { NavLink, useHistory } from "react-router-dom";
import "./navbar.scss";
import { Auth } from "aws-amplify";
import { DEPLOYMENT_REGION, SHOW_VISION_MENU,SHOW_OPTIX_MENU, SHOW_FILE_COPY_UTLITY,SHOW_OPTIX_OPTION, SHOW_MULTIPLE_MACHINE_LIST, SHOW_VITROS_MENU } from "../../utils/constants"
import { navObjects } from './constants'

export default function Navbar(props){

    const history = useHistory()

    const [ navObjList, setNavObjList ] = useState(navObjects)
    const [ nameOfTheMachine, setNameOfTheMachine ] = useState('Vitros')
    const [ isOpen, setIsOpen ] = useState(false)
    const [ toggler, setToggler ] = useState(false)
    const [ menuClass, setMenuClass] = useState('dropdown-menu')

    useEffect(() => {
        if( SHOW_FILE_COPY_UTLITY === "TRUE" ){
            let tempNavObjs = JSON.parse(JSON.stringify(navObjList))
            tempNavObjs['Vitros'].push({ name: "File Copy Util", route: "/Vitros/fileCopyUtil"})
            setNavObjList(tempNavObjs)
        }
    },[])

    useEffect(() => {
        console.log('this is history', history)
        if(history.location.pathname.includes('Vitros')) setNameOfTheMachine('Vitros')
        else if( history.location.pathname.includes('Vision')) setNameOfTheMachine('Vision')
        else if(history.location.pathname.includes('Optix')) setNameOfTheMachine('Optix')
    },[history.location.pathname])

    useEffect(() => {
        setMenuClass(`${menuClass}${isOpen ? ' show': ''}`)
    },[isOpen])


   const toggleOpen = () => setIsOpen(!isOpen)
   const toggleNav = () => setToggler(!toggler)
   const refreshPage = () => window.location.reload(false)
   const logoutUser = () => DEPLOYMENT_REGION === 'USA' ? Auth.signOut() : props.signoutUser()

   return <>
   <nav className="navbar shadow-sm sticky-top navbar-light navbar-expand-lg">
     <div className="container-fluid">
       <span className="navbar-brand ortho-logo" />
       <button
         className="navbar-toggler"
         type="button"
         data-bs-toggle="collapse"
         data-bs-target="#navbarSupportedContent"
         aria-controls="navbarSupportedContent"
         aria-expanded="false"
         aria-label="Toggle navigation"
         onClick={toggleNav}
       >
         <span className="navbar-toggler-icon"></span>
       </button>
       <div
         className={
           toggler
             ? "collapse navbar-collapse show"
             : "collapse navbar-collapse"
         }
         id="navbarSupportedContent"
       >
         <ul className="navbar-nav me-auto mb-2 mb-lg-0 justify-content-end w-100">
           {navObjList[nameOfTheMachine].map((item, index) => {
               return (
                 <li className="nav-item" key={index}>
                   <NavLink
                     className="nav-link"
                     exact
                     activeClassName="selected"
                     to={item.route}
                   >
                     {item.name}
                   </NavLink>
                 </li>
               );
           })}
           {
            ( () => {
                if ( SHOW_MULTIPLE_MACHINE_LIST === "TRUE"){
                    return <div onClick={toggleOpen}>
                    <button
                      className="btn btn-secondary dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                    >
                      {nameOfTheMachine}
                    </button>
                    <div
                      className={menuClass}
                      aria-labelledby="dropdownMenuButton"
                    >
                      <>
                      {
                        (() => {
                          if(SHOW_VITROS_MENU && SHOW_VITROS_MENU === 'TRUE'){
                            return <li onClick={refreshPage} className="nav-item" >
                            <NavLink
                              className="nav-link"
                              exact
                              activeClassName="selected"
                              to="/Vitros"
                            >
                              Vitros
                            </NavLink>
                          </li>
                          }else{
                            return null
                          }
                        })()
                      }
                      </>
                      <>
                        {
                          (() => {
                            if(SHOW_VISION_MENU && SHOW_VISION_MENU === "TRUE"){
                              return <li onClick={refreshPage} className="nav-item">
                              <NavLink
                                className="nav-link"
                                exact
                                activeClassName="selected"
                                to="/Vision"
                              >
                                Vision
                              </NavLink>
                            </li>
                            }else{
                              return null
                            }
                          })()
                        }
                      </>
                      <>
                      {
                        ( () => {
                          console.log('show optix option', typeof SHOW_OPTIX_OPTION, SHOW_OPTIX_OPTION, SHOW_VISION_MENU)
                          if(SHOW_OPTIX_MENU && SHOW_OPTIX_MENU==='TRUE'){
                            return <li onClick={refreshPage} className="nav-item">
                            <NavLink
                              className="nav-link"
                              exact
                              activeClassName="selected"
                              to="/Optix"
                            >
                              Optix
                            </NavLink>
                            </li>
                          }else{
                            return null
                          }
                        })()
                      }
                      </>
                    </div>
                </div>
                }else{
                    return null
                }
            })()
           }
         </ul>
         <div>
           <button
             type="button"
             className="btn btn-outline-danger"
             title="Logout"
             onClick={() => logoutUser()}
           >
             <svg
               xmlns="http://www.w3.org/2000/svg"
               width="16"
               height="16"
               fill="currentColor"
               className="bi bi-arrow-bar-right"
               viewBox="0 0 16 16"
             >
               <path
                 fillRule="evenodd"
                 d="M6 8a.5.5 0 0 0 .5.5h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L12.293 7.5H6.5A.5.5 0 0 0 6 8zm-2.5 7a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5z"
               ></path>
             </svg>
           </button>
         </div>
       </div>
     </div>
   </nav>
 </>
}