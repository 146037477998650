export const API_GATEWAY_VISION = process.env.REACT_APP_API_GATEWAY_VISION;
export const API_GATEWAY_VITROS = process.env.REACT_APP_API_GATEWAY_VITROS;
export const API_GATEWAY_OPTIX = process.env.REACT_APP_API_GATEWAY_OPTIX;
export const COGNITO_REGION = process.env.REACT_APP_COGNITO_REGION
export const COGNITO_USERPOOL_ID = process.env.REACT_APP_COGNITO_USERPOOL_ID
export const COGNITO_USERPOOL_WEB_CLIENT_ID = process.env.REACT_APP_COGNITO_USERPOOL_WEB_CLIENT_ID
export const COGNITO_DOMAIN = process.env.REACT_APP_COGNITO_DOMAIN
export const COGNITO_SIGN_IN_URL = process.env.REACT_APP_COGNITO_SIGN_IN_URL
export const COGNITO_SIGN_OUT_URL = process.env.REACT_APP_COGNITO_SIGN_OUT_URL
export const DEPLOYMENT_REGION = process.env.REACT_APP_DEPLOYMENT_REGION
export const KEY_CLOAK_URL = process.env.REACT_APP_KEY_CLOAK_URL
export const KEY_CLOAK_LOGOUT_URL = process.env.REACT_APP_KEY_CLOAK_LOGOUT_URL
export const ACCESS_USER_TYPE = process.env.REACT_APP_ACCESS_USER_TYPE
export const SHOW_VISION_MENU = process.env.REACT_APP_SHOW_VISION_MENU
export const SHOW_OPTIX_MENU = process.env.REACT_APP_SHOW_OPTIX_MENU
export const SHOW_FILE_COPY_UTLITY = process.env.REACT_APP_SHOW_FILE_COPY_UTLITY
export const SHOW_OPTIX_OPTION = process.env.REACT_APP_SHOW_OPTIX_OPTION
export const SHOW_MULTIPLE_MACHINE_LIST = process.env.REACT_APP_SHOW_MULTIPLE_MACHINE_LIST
export const HOME_MACHINE_PAGE = process.env.REACT_APP_HOME_MACHINE_PAGE
export const SHOW_VITROS_MENU = process.env.REACT_APP_SHOW_VITROS_OPTION