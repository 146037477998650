import './App.scss';
import react from 'react';
import { currentConfig } from './utils/amplify-config'
import Amplify, { Auth } from 'aws-amplify';
import RouterInfo from './utils/router'
import { checkAppAccessibility } from './utils/accessibility-check';
import PermissionError from './components/error/permission-error';

Amplify.configure(currentConfig);

class CApp extends react.Component {

  constructor(props) {
    super(props);
    this.state = {
      user: {},
      userSession: {}
    }
  }

  componentDidMount() {
    console.log(Auth)
    Auth.currentAuthenticatedUser()
      .then(userInfo => {
        console.log({ userInfo }); 
        this.setState({user: userInfo.attributes})
      })
      .catch(() => {
        console.log("Not signed in redirecting to sign in");
        Auth.federatedSignIn();
      });
    this.getSession();
  }

  getSession = async () => {
    await Auth.currentSession().then(userSession => {
      console.log(userSession)
      this.setState({ userSession });
    });
  }

  canUserAccess () {
    return checkAppAccessibility(this.state.user['custom:UserType']);
  }

  render() {
    if (this.state.userSession && Object.keys(this.state.userSession).length !== 0) {
      return (
        this.state.user && this.canUserAccess() ? <RouterInfo /> :  <PermissionError/>
      );
    } else {
      return (
        <>
          You wills be automatically redirected or{" "}
          <a href="#" onClick={Auth.federatedSignIn}>
            click here to login
          </a>
          .
        </>
      );
    }
  }

}

export default CApp;
