import "./no-data-card.scss";

const NoDataCard = (props) => {

    const title = props.title ? props.title : '';

    return (
        <div className="noDataDiv">
            <h6 >{title}</h6>
            <div >
                <p className="noData">No Data available to display.</p>
            </div>
        </div>
        
    )
}

export default NoDataCard;
