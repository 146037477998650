import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { Button, FormControl, Grid, InputLabel, LinearProgress, MenuItem, Select, TextField, Tooltip } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Card from "../../components/card/card";
import CustomizedSnackbars from "../../components/snackbar/customSnackbar";
import { executeJob, getUIInputData } from "../../services/Vitros/file-copy-utility-service";
import './FileCopyUtil.css';

const FileCopyUtil = () => {

    const snackbar = React.useRef(null);
    let [source, setSources] = useState(['']);
    const [destination, setDestination] = useState(['']);

    const [selectedSource, setSelectedSource] = useState({'account': ''});
    const [selectedDest, setSelectedDest] = useState({'account': ''});

    const [fileTypes, setFileTypes] = useState(['']);
    const [selectedFileType, setSelectedFileType] = useState('');

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const [startDay, setStartDay] = useState(0);
    const [endDay, setEndDay] = useState(0);
    const [month, setMonth] = useState(0);
    const [year, setYear] = useState(0);

    const threeMonthsAgo = new Date(moment().subtract(3, 'months').format('YYYY-MM-DD'));
    const [sMinDate, setSMinDate] = useState(threeMonthsAgo);
    const [eMinDate, setEMinDate] = useState();
    const [eMaxDate, setEMaxDate] = useState();

    const [modelTypes, setModelTypes] = useState(['']);
    const [jnumberInput, setJnumberInput] = useState({});
    const [sleep, setSleep] = useState();
    
    const [showSpinner, setShowSpinner] = useState(false);

    const [snackbarData, setSnackbarData] = useState({ message: '', type: ''});

    useEffect(() => {
        setShowSpinner(true);
        getUIInputData()
        .then((resp) => {
            setSources(resp.data.env);
            setDestination(resp.data.env);
            setModelTypes(resp.data.models);
            setFileTypes(resp.data.file_types);
            setSleep(resp.data.sleep);
            setShowSpinner(false);
        }).catch((e) => {
            setShowSpinner(false);
            console.error('error', e);
        });
    }, []);

    useEffect(() => {
        const tempDate = moment(startDate);
        setStartDay(tempDate.date());
        setMonth(tempDate.month()+1);
        setYear(tempDate.year());

        const lastDayOfMonth = new Date(moment(startDate).endOf('month').format('YYYY-MM-DD'));
        setSMinDate(threeMonthsAgo);

        setEndDate(startDate);
        setEMinDate(startDate);
        setEMaxDate(lastDayOfMonth);

    }, [startDate]);

    useEffect(() => {
        const tempDate = moment(endDate);
        setEndDay(tempDate.date());
    }, [endDate]);

    const selectSource = (e) => {
        let acc =  source.filter((val) => val.account === e.target.value);
        if (acc.length > 0) {
            setSelectedSource(acc[0]);
        }
    }

    const selectDest = (e) => {
        let acc =  destination.filter((val) => val.account === e.target.value);
        if (acc.length > 0) {
            setSelectedDest(acc[0]);
        }
    }

    const selectFileType = (e) => {
        setSelectedFileType(e.target.value);
    }

    const changeStartDate = (e) => {
        setStartDate(e);
    }

    const changeEndDate = (e) => {
        setEndDate(e);
    }

    function changeModelInput(e) {
        const id = e.target.id;
        const val = e.target.value;
        
        setJnumberInput((prev) => {
            return {
                ...prev,
                [id]: val
            }
        });
    }

    function structureModels () {
        let output = {};
        for (let key in jnumberInput) {
            output[key] = jnumberInput[key].split(',').map((val) => val.trim());
        }
        return output;
    }

    const submitJob = async () => {

        if (!selectedSource.account) {
            setSnackbarData({ message: 'Source not selected', type: 'error'});
            snackbar.current();
            return;
        }

        if (!selectedDest.account) {
            setSnackbarData({  message: 'Destination not selected', type: 'error'});
            snackbar.current();
            return;
        }

        if (selectedSource.account === selectedDest.account) {
            setSnackbarData({  message: 'Source and Destination can not be same', type: 'error'});
            snackbar.current();
            return;
        }

        if (selectedFileType === '') {
            setSnackbarData({  message: 'File type not selected', type: 'error'});
            snackbar.current();
            return;
        }

        let tempModels = structureModels();

        if (!Object.keys(tempModels).length) {
            setSnackbarData({  message: 'Enter at least one instrument id', type: 'error'});
            snackbar.current();
            return;
        }

        setShowSpinner(true);

        let request = {
            "iYear": year,
            "iMonth": month,
            "iStart": startDay,
            'iEnd': endDay,
            "iSleep": sleep,
            "iSourceAccount": selectedSource.account,
            "iDestAccount": selectedDest.account,
            // "iSourcePrefix": selectedSource.bucket_subfolder,
            // "iDestPrefix": selectedDest.bucket_subfolder,
            "iFileType": selectedFileType,
            "iModels": tempModels
        }

        try {
            //clear all input values after submit ?
            //can source and destination be same ?
            let response = await executeJob(request);
            console.log('reqponse ********', response);
            setSnackbarData({  message: 'Task Created Successful', type: 'success'});
            snackbar.current();
        } catch (e) {
            setSnackbarData({ message: 'Error Occured', type: 'error'});
            snackbar.current();
        }
        
        setShowSpinner(false);
    }

    return (
        <div>
            <div className="row mb-3">
                <Card title="File Copy Utility Parameters">

                    {/* Source and destination dropdowns */}
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-evenly"
                        alignItems="stretch"
                    >

                        <Grid item xs={3}>
                            <FormControl fullWidth>
                                <InputLabel id="source">Source</InputLabel>
                                <Select
                                    labelId="source-label"
                                    id="source-select"
                                    value={selectedSource.account}
                                    label="Source"
                                    onChange={selectSource}
                                >
                                    {
                                        source.map((val, index) => {
                                            return (
                                                <MenuItem key={index} value={val.account}>{val.account}</MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth>
                                <InputLabel id="dest">Destination</InputLabel>
                                <Select
                                    labelId="dest-label"
                                    id="dest-select"
                                    value={selectedDest.account}
                                    label="Destination"
                                    onChange={selectDest}
                                >
                                    {
                                        destination.map((val, index) => {
                                            return (
                                                <MenuItem key={index} value={val.account}>{val.account}</MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>


                    {/* Start and End date datepickers */}
                    <div className="grid-spacing">
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-evenly"
                            alignItems="stretch"
                        >

                            <Grid item xs={3}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        label="Start Date"
                                        value={startDate}
                                        onChange={changeStartDate}
                                        disableFuture
                                        minDate={sMinDate}
                                        renderInput={(params) => <TextField sx={{ m: 1 }} {...params} />}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={3}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        label="End Date"
                                        value={endDate}
                                        onChange={changeEndDate}
                                        disableToolbar={true}
                                        minDate={eMinDate}
                                        maxDate={eMaxDate}
                                        disableFuture
                                        renderInput={(params) => <TextField sx={{ m: 1 }} {...params} />}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth>
                                    <InputLabel id="file_type">File Type</InputLabel>
                                    <Select
                                        labelId="file-type-label"
                                        id="file-type-select"
                                        value={selectedFileType}
                                        label="File Type"
                                        onChange={selectFileType}
                                    >
                                        {
                                            fileTypes.map((val, index) => {
                                                return (
                                                    <MenuItem key={index} value={val}>{val}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </div>

                    {showSpinner ?  <LinearProgress /> : <></>}

                    {/* model names and jnumber input values */}
                    <div className="grid-spacing">
                        <Card title="Intruments to copy">
                        <Grid container spacing={2}>
                        <Grid item xs={6}>
                                            
                            {
                                modelTypes.map((val, index) => {
                                    return (
                                        <div className="model-div">
                                            <Tooltip title="Provide comma(,) separated values. i.e. 123456, 654321">
                                                <TextField id={val} onChange={changeModelInput} label={val} size="small" variant="outlined" fullWidth/>
                                            </Tooltip>
                                        </div>
                                    )
                                })
                            }
                            </Grid>
                            <Grid item xs={6}>
                                <div className=""></div>
                            </Grid>

                        </Grid>
                        </Card>
                    </div>


                    {/* Email Id and submit */}
                    <div className="grid-spacing">
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-evenly"
                            alignItems="stretch"
                        >

                            <Grid item xs={8}>
                                <div>
                                    <span>Note: If you want to get email notification of job completion details please 
                                        send email to prashanth.aadepu@orthoclinicaldiagnostics.com or brian.jonigan@orthoclinicaldiagnostics.com</span>
                                </div>
                            </Grid>
                            <Grid item xs={3}>
                                <Button variant="contained" size="large" onClick={submitJob}>Submit</Button>
                            </Grid>
                        </Grid>
                    </div>
                </Card>
            </div>

            <CustomizedSnackbars snackbar={snackbar} message={snackbarData.message} type={snackbarData.type}/>
        </div>
    )
}

export default FileCopyUtil;
