import {Link, useHistory} from 'react-router-dom'
import LogoImg from '../../assets/images/econn-logo.png'

import './style.css'
export default function Error_404(props){
    const history = useHistory()
    const goBack = () => history.goBack()
    return<>
    <div className='container-404'>
        <img src={LogoImg} alt="Logo" />
        <h1>Page Not Found</h1>
        <h4><Link onClick={goBack}>Back</Link></h4>
        <h4><Link to="/">Home</Link></h4>
    </div>
    </>
}