import get from '../../utils/http-utils'

export async function getErrorNames() {
    let headers = {
        "content-type": "application/json"
    }

    return get("/lookup/errorname", headers)
        .then(resp => {
            const errors = Object.values(resp.data).reduce((acc, val, ind) => {
                let tempObj = { key: val, value: val }
                acc.push(tempObj)
                return acc;
            }, [])
            return { data: errors }

        }, err => {
            return { error: err.message };
        })
}

export function getChartDataByErrorName() {
    return get("/charts/errorsbyerrorname")
        .then(resp => {
            console.log("resp", resp);
            if (resp.data?.length > 0) {
                const finalData = simplifyChartData(resp)
                const series = Object.keys(finalData.series).map(val => ({ name: val, data: finalData.series[val] }));
                return { data: { categories: finalData.categories, series } }
            }
        }, err => {
            console.log("instruments resp err", err.message)
            return { error: err.message }
        })
}

export function getChartDataByDay() {
    return get("/charts/errorsbyday")
        .then(resp => {
            console.log("resp", resp);
            if (resp.data?.length > 0) {
                const finalData = simplifyChartData(resp);
                const series = Object.keys(finalData.series).map(val => ({ name: val, data: finalData.series[val] }));
                return { data: { categories: finalData.categories, series } };
            }
        }, err => {
            return { error: err.message }
        })
}
function simplifyChartData(resp) {
    const finalData = resp.data.reduce((acc, val, ind, arr) => {
        const { date } = val;
        Object.keys(val).filter(value => value !== "date").forEach((item) => {
            if (acc.series.hasOwnProperty(item) && acc.series[item].length === ind) {
                acc.series[item] = [...acc.series[item], val[item]]
            } else if (acc.series.hasOwnProperty(item) && acc.series[item].length !== ind) {
                const diff = ind - acc.series[item].length;
                const arr = new Array(diff - 1).fill(0);
                acc.series[item] = [...arr, val[item]]
            }
            else {
                if (ind > 0) {
                    const arr = new Array(ind - 1).fill(0);
                    acc.series[item] = [...arr, val[item]]
                } else {
                    acc.series[item] = [val[item]]
                }

            }

        })
        acc.categories.push(date);
        return acc;

    }, { categories: [], series: {} })

    console.log(finalData)
    return finalData;
}