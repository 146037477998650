import { FormControl, Input, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import moment from 'moment';
import react from 'react'
import Card from '../../../components/card/card';
import Datetime from '../../../components/datetime/datetime';
import Loader from '../../../components/loader/loader';
import Modal from '../../../components/modal/modal';
import StrippedTable from '../../../components/table/table';
import BasicColumnChart from '../../../components/basic-column-chart/basic-column-chart';
import { getErrorNames, getChartDataByErrorName, getChartDataByDay } from '../../../services/Vitros/errors-service';
import get from '../../../utils/http-utils';
import './errors.scss';
import NoDataCard from '../../../components/card/no-data/no-data-card';

class Errors extends react.Component {

    constructor(props) {
        super(props);
        this.state = {
            from: moment(Date.now()).startOf('day').format(),
            to: moment(Date.now()).format(),
            errorName: "",
            zipfileId: "",
            fileName: "",
            errorsTableData: { headers: [], data: [] },
            showModal: false,
            modalRowIndex: -1,
            loadedErrorData: false,
            tableMessage: "",
            paginationLoaded: false,
            currentPage: 1,
            paginationMessage: "",
            pageCount: -1,
            selectedErrors: [],
            errorsLoaded: true,
            errors: [],
            errorByNameCategories: [],
            errorByNameSeries: [],
            errorByDayCategories: [],
            errorByDaySeries: [],
            errorByNameDataLoaded: false,
            errorByDayDataLoaded: false
        }


        this.setFrom = this.setFrom.bind(this);
        this.setTo = this.setTo.bind(this);
        this.setErrorName = this.setErrorName.bind(this);
        this.setFileName = this.setFileName.bind(this);
        this.setZipfileId = this.setZipfileId.bind(this);
        this.showModal = this.showModal.bind(this);
        this.getErrors = this.getErrors.bind(this);
    }

    showModal(ind) {
        this.setState({ showModal: !this.state.showModal, modalRowIndex: ind });
    }

    setTo(to) {
        const endDate = moment(to.target.value).format()
        this.setState({ to: endDate });
        console.log(moment(to.target.value).format())
        this.getErrors({ endDate });
    }

    setFrom(from) {
        const startDate = moment(from.target.value).format();
        this.setState({ from: startDate });
        console.log(moment(from.target.value).format())
        this.getErrors({ startDate });
    }

    setErrorName(event) {
        const errorName = event.target.value;
        // this.setState({ errorName });
        // this.getErrors({ errorName });
        this.setState({ selectedErrors: event.target.value });
        this.getErrors({ errorName: event.target.value.join(",") })
    };

    setFileName(event) {
        const fileName = event.target.value;
        this.setState({ fileName });
        this.getErrors({ zipfileName: fileName });

    };

    setZipfileId(event) {
        const zipfileId = event.target.value;
        this.setState({ zipfileId });
        this.getErrors({ zipfileId });
    };

    getErrors({ startDate = this.state.from, endDate = this.state.to, zipfileName = this.state.fileName, zipfileId = this.state.zipfileId, errorName = this.state.selectedErrors.join(","), page = 1, pageSize = 100 } = {}) {
        this.setState({ loadedErrorData: false })
        const params = {
            startDate,
            endDate,
            zipfileName,
            errorName,
            zipfileId,
            page,
            pageSize
        };
        if (page === 1) this.getErrorsPagination(params)
        this.setState({ currentPage: page });
        get("/zipfiles/errors",
            { "content-type": "application/json" }, params
        ).then(resp => {
            console.log("zipfile status --------------------", resp.data)
            if (resp.data.length > 0) {
                const headers = Object.keys(resp.data[0]);

                const data = resp.data.map((val, ind) => {
                    val["stack_trace_data"] = val["stack_trace"];
                    val["stack_trace"] = (
                        <span className="icon" onClick={() => this.showModal(ind)} title="info">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-info" viewBox="0 0 16 16">
                                <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                            </svg>
                        </span>
                    )
                    return val;
                });
                const errors = { headers, data };
                this.setState({ errorsTableData: errors, loadedErrorData: true, tableMessage: "" });
            } else {
                this.setState({ errorsTableData: { headers: [], data: [] }, loadedErrorData: true, tableMessage: "No data found for give criteria." });
            }
        }, err => {
            this.setState({ errorsTableData: { headers: [], data: [] }, loadedErrorData: true, tableMessage: "Could not load data due to error.(Message:" + err.message + ")" });
        })
    }

    getErrorsPagination({ startDate = this.state.from, endDate = this.state.to, zipfileName = "", zipfileId = "", errorName = "", pageSize = 100 } = {}) {
        get("/pagination/zipfiles-errors",
            { "content-type": "application/json" },
            {
                startDate,
                endDate,
                zipfileId,
                zipfileName,
                errorName,
                pageSize
            }
        ).then(resp => {
            console.log(resp, "error pagination");
            this.setState({ paginationLoaded: true, paginationMessage: "", pageCount: resp.data[0]?.count })
        }, err => {
            this.setState({ paginationLoaded: true, paginationMessage: "Could not load pagination.(Message:" + err.message + ")" })
        })
    }

    getChartDataByErrorName() {
        getChartDataByErrorName().then(({ error, data }) => {
            if (!error) {
                this.setState({ errorByNameCategories: data.categories, errorByNameSeries: data.series });
                this.setState({ errorByNameDataLoaded: true });
            }
        });
    }

    getChartDataByDay() {
        getChartDataByDay().then(({ error, data }) => {
            if (!error) {
                this.setState({ errorByDayCategories: data.categories, errorByDaySeries: data.series });
                this.setState({ errorByDayDataLoaded: true });
            }
        });
    }


    componentDidMount() {
        this.getErrors();
        this.setState({ errorsLoaded: false })
        getErrorNames().then(data => {
            console.log(data, "dataa errorssss");
            this.setState({ errors: data.data })
            this.setState({ errorsLoaded: true });
        })
        this.getChartDataByErrorName();
        this.getChartDataByDay();
    }

    render() {
        return (<>
            <div className="row mb-3">
                <div className="col-sm-6 col-md-6">
                    <Datetime title="Created Date" setTo={this.setTo} setFrom={this.setFrom} />
                </div>
                <div className="col-sm-3 col-md-3">
                    {/* <Card title="Error name">
                        <TextField onChange={this.setErrorName} className="w-100" id="standard-basic" label="Error name" />
                    </Card> */}
                    <Card title="Error Name">
                        {
                            this.state.errorsLoaded ? (
                                <FormControl className="custom-form-control" >
                                    <InputLabel id="demo-mutiple-name-label">Error Name</InputLabel>
                                    <Select
                                        labelId="demo-mutiple-name-label"
                                        id="demo-mutiple-name"
                                        multiple
                                        value={this.state.selectedErrors}
                                        onChange={this.setErrorName}
                                        input={<Input />}
                                    // MenuProps={MenuProps}
                                    >
                                        {this.state.errors.map((item) => (
                                            <MenuItem key={item.key} value={item.value}>
                                                {item.key}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )

                                : <div className="w-100 d-flex">
                                    <Loader />
                                </div>
                        }
                    </Card>
                </div>
                <div className="col-sm-3 col-md-3">
                    <Card title="Zipfile ID">
                        <TextField onChange={this.setZipfileId} className="w-100" id="standard-basic" label="Zipfile ID" />
                    </Card>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-sm-3 col-md-3">
                    <Card title="Filename">
                        <TextField onChange={this.setFileName} className="w-100" id="standard-basic" label="Filename" />
                    </Card>
                </div>
            </div>

            <div className="mb-3">

                <Card title="Errors">
                    {this.state.loadedErrorData ?
                        <StrippedTable {...this.state.errorsTableData}
                            paginationCallout={this.getErrors}
                            currentPage={this.state.currentPage}
                            paginationLoaded={this.state.paginationLoaded}
                            pageCount={this.state.pageCount}
                            paginationMessage={this.state.paginationMessage}>

                            <Modal showModal={this.state.showModal} closeModal={this.showModal}
                                modalBody={this.state.modalRowIndex >= 0 ? this.state.errorsTableData.data[this.state.modalRowIndex]["stack_trace_data"] : "Improper Index selected"}
                                modalTitle={"Stack Trace"}
                            ></Modal>
                        </StrippedTable>
                        : <Loader />}

                </Card>

            </div>
            <div className=" card shadow-sm mb-3 d-flex">
                {this.state.errorByNameCategories.length > 0 && this.state.errorByNameSeries.length > 0 ?
                    <BasicColumnChart title="Error count by error name"
                        categories={this.state.errorByNameCategories}
                        series={this.state.errorByNameSeries}
                        xAxisTitle="Created Date"
                        yAxisTitle="file_name(Count Distinct)"
                        legend={true}
                    />
                    : (this.state.errorByNameDataLoaded && (this.state.errorByNameCategories.length === 0 || this.state.errorByNameSeries.length === 0) ?
                        <NoDataCard title="Error count by error name"/>
                        : <Loader />)
                }
            </div>
            <div className=" card shadow-sm mb-3 d-flex">
                {this.state.errorByDayCategories.length > 0 && this.state.errorByDaySeries.length > 0 ?
                    <BasicColumnChart title="Error count by day"
                        categories={this.state.errorByDayCategories}
                        series={this.state.errorByDaySeries}
                        xAxisTitle="Created Date"
                        yAxisTitle="file_name(Count Distinct)"
                    />
                    : (this.state.errorByDayDataLoaded && (this.state.errorByDayCategories.length === 0 || this.state.errorByDaySeries.length === 0) ?
                    <NoDataCard title="Error count by day"/>
                    : <Loader />)
                }
            </div>
        </>)
    }
}

export default Errors;